import { faAngleLeft, faFile, faImage, faMagnifyingGlass, faSave, faShare, faShareAlt, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Children, Component } from 'react';
import { json, Link } from 'react-router-dom';
import "./a.css"
import { toast } from 'react-toastify';
import { apiUrl } from '../../comps/Config';
import { StaticDialog } from 'react-st-modal';
import { getCookie } from '../../comps/getCookie';

import male from "../../images/male.png"

class NovaArvore extends Component {
  constructor(props) {
    super(props);
    this.state = {

      tree:[{"id":"grIT","label":"Nome do Ancestral","children":[],"genero":"m","req":"Italiano (a)"}],
      ndc:'',
      sex:'',
      tip:'',
      addM:false,
      ft1:false,
      novo:{},
      addno:undefined,
      isDragging: false,
      x: 0,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      scale: 1, 
      node:undefined

    }


  
  }
 GerToken(tamanho) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let codigo = '';
    
    for (let i = 0; i < tamanho; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      codigo += caracteres[indiceAleatorio];
    }
    
    return codigo;
  }
  
  handleMouseDown = (e) => {
    this.setState({
      isDragging: true,
      offsetX: e.clientX - this.state.x,
      offsetY: e.clientY - this.state.y,
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = (e) => {
    if (this.state.isDragging) {
      this.setState({
        x: e.clientX - this.state.offsetX,
        y: e.clientY - this.state.offsetY,
      });
    }
  };

  handleMouseUp = () => {
    this.setState({ isDragging: false });
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };





  componentDidMount(){
document.title = "Nova árvore"
const rootElement = document.getElementById('root');
if(rootElement){
  rootElement.classList.add("fixa")
}

  }

  componentWillUnmount() {
    const rootElement = document.getElementById('root');
    if(rootElement){
      rootElement.classList.remove("fixa")
    }
}




  lockScroll = () => {
    document.body.style.overflow = 'hidden';
}


  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedTree = [...this.state.tree];

    if (isChecked) {
      // Se marcado, adiciona `cjg: {}`
      updatedTree[0] = { ...updatedTree[0], cjg: {} };
    } else {
      // Se desmarcado, remove `cjg`
      const { cjg, ...rest } = updatedTree[0]; // Remove a chave `cjg`
      updatedTree[0] = rest;
    }

    this.setState({ tree: updatedTree });
  };

  updateNode = (node, key, value) => {
    // Atualize o valor do nó
    node[key] = value;
    
    // Defina o novo estado com a árvore atualizada
    this.setState({ tree: this.state.tree });
  };



Bandeira(e){
  switch(e){
    case 'Brasil':
    return '🇧🇷'

    case 'Itália':
    return '🇮🇹'

    case 'Portugal':
    return '🇵🇹'

    case 'Espanha':
    return '🇪🇸'

    case 'Polonia':
    return '🇵🇱'
    

    

  }
}

removeNode = (id) => {
  const removeNodeRecursively = (nodes) => {
    return nodes.filter(node => {
      if (node.id === id) return false;
      if (node.children) {
        node.children = removeNodeRecursively(node.children);
      }
      return true;
    });
  };
  
  const updatedTree = removeNodeRecursively(this.state.tree);
  this.setState({ tree: updatedTree });
};


addNode = (id) => {
  const addChildrenRecursively = (nodes) => {
    return nodes.map(node => {
      if (node.id === id) {
        // Add an empty children array if it doesn't already exist
        if (!node.children) {
          node.children = [{id:this.GerToken(4)}];
        }
      } else if (node.children) {
        node.children = addChildrenRecursively(node.children);
      }
      return node;
    });
  };

  const updatedTree = addChildrenRecursively(this.state.tree);
  this.setState({ tree: updatedTree });
};



  renderTree = (node) => {
    return (
      
   
       <>
           <div class="card mb-3">
        
      <div class="card-header d-flex align-items-center justify-content-between">
           <span>{node.req ? node.req : 'Novo descedente'}</span>
           <button className='btn btn-danger' onClick={() => this.removeNode(node.id)}>
            <FontAwesomeIcon icon={faTrash}/>
           </button>
      </div>
        
      <div class="card-body">

      <div className='add-img mb-3'>
<FontAwesomeIcon icon={faFile}/>
<input type='file'/>
     </div>
        
   <input type="text" id="descendantName" value={node.label} placeholder="Nome do Descendente" onChange={(e)=>{
this.updateNode(node, 'label', e.target.value)
}}/>

 <select id="descendantGender"  value={node.genero || 's'} onChange={(e)=>{
this.updateNode(node, 'genero', e.target.value)
 }}>
                   <option value="s">Gênero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>
               
 
 <select id="descendantRelation"  value={node.req || 's'} onChange={(e)=>{

this.updateNode(node, 'req', e.target.value)
  
 }} >              <option value="s">Denominação:</option>
                   <option value="FILHO (Requerente)">FILHO (Requerente)</option>
                   <option value="FILHOS (Menores)">FILHOS (Menores)</option>
                   <option value="PAI">PAI</option>
                   <option value="MÃE">MÃE</option>
                   <option value="AVO (a)">AVO (a)</option>
                   <option value="BISAVO (a)">BISAVO (a)</option>
                   <option value="TRISAVO (a)">TRISAVO (a)</option>
               </select>
              
<div className='mt-3'>
<input type="text" id="descendantName" value={node.lnac} placeholder="Local de nascineto" onChange={(e)=>{
this.updateNode(node, 'lnac', e.target.value)
}}/>
<input type="date" id="descendantName" value={node.dnac} placeholder="Local de nascineto" onChange={(e)=>{
this.updateNode(node, 'dnac', e.target.value)
}}/>


<select id="descendantRelation" value={node.ncdd || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
  this.updateNode(node, 'ncdd', e.target.value)
 }} >              <option value="s">Nacionalidade:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>


</div>



<div className='mt-2'>
          
          <label>Falecido (a): </label>  <input 
          type="checkbox" 
          style={{marginLeft:5}}
          checked={node.flc ? true:false}
          onChange={(e)=>{
            if(e.target.checked){
              node['flc'] = {};
              this.setState({ tree: this.state.tree });
            }else{
        
             delete node['flc']
             this.setState({ tree: this.state.tree });
            }
      



          }}
    />
</div>

{node.flc && <div className='mt-3 mb-3'>
  <input type="text" id="spouseName" placeholder="Local de falecimento" onChange={(e)=>{
  node['flc']['local'] = e.target.value;
  this.setState({ tree: this.state.tree });
}}/>
  <input type="date" id="spouseName" placeholder="data" onChange={(e)=>{
  node['flc']['data'] = e.target.value;
  this.setState({ tree: this.state.tree });
}}/>
  </div>}




  {node.cjg == undefined ? <>
<button className='btn btn-primary mt-2' onClick={(e)=>{
   node['cjg'] = {};
   this.setState({ tree: this.state.tree })
}}>
Adicionar cônjuge
</button>
</> : <>
<div class="card mb-2 mt-3">
        
      <div class="card-header d-flex align-items-center justify-content-between">
           <span>Conjuguê</span>
           <button className='btn btn-danger' onClick={()=>{
  
  delete node['cjg']
  this.setState({ tree: this.state.tree });

           }}>
            <FontAwesomeIcon icon={faTrash}/>
           </button>
         
      </div>
        
      <div class="card-body">
      <input type="text" id="spouseName" value={node['cjg']['label']} placeholder="Nome de solteira" onChange={(e)=>{
           node['cjg']['label'] = e.target.value;
           this.setState({ tree: this.state.tree });           
      }}/>



<input type="text" id="spouseName" value={node['cjg']['nomec']} placeholder="Nome de casada" onChange={(e)=>{
                      node['cjg']['nomec'] = e.target.value;
                      this.setState({ tree: this.state.tree });                  
                }}/>



            <select id="spouseGender" value={node['cjg']['genero'] || 's'} onChange={(e)=>{
              if(e.target.value == "s") return;
              node['cjg']['genero'] = e.target.value;
              this.setState({ tree: this.state.tree });    
                }}>
                <option value="s">Genero:</option>
                <option value="m">Masculino</option>
                <option value="f">Feminino</option>
            </select>

            <div style={{marginTop:10}}>
            
            <input type="text" id="spouseName" value={node['cjg']['lnac']} placeholder="Local de nascimento:" onChange={(e)=>{
                     node['cjg']['lnac'] = e.target.value;
                     this.setState({ tree: this.state.tree });          
                }}/>



<input type="date" id="spouseName" value={node['cjg']['data']} placeholder="Local de nascimento:" onChange={(e)=>{
                   node['cjg']['data'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/>   


               
 <select id="descendantRelation" value={node['cjg']['ncdd'] || 's'} onChange={(e)=>{
if(e.target.value == "s") return;
node['cjg']['ncdd'] = e.target.value;
this.setState({ tree: this.state.tree });
 }} >              <option value="s">Nacionalidade:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>
            </div>
      </div>
      </div>

</>}




              
        <div className='mt-3'>
       
        </div> 

             
      </div>
     
    
       
      <div class="card-footer d-flex justify-content-end">
    <button class="btn btn-primary">Salvar</button>
  </div>

        
        </div>
        {(!node.children || node.children.length === 0) && <div className='d-flex justify-content-center'>
 
          <button className="btn btn-primary" onClick={() =>{
            this.setState({addno:node.id})
          }}>Adicionar</button>
        
        </div>
        
        }
        {node.children && node.children.length > 0 && (
         <> {node.children.map((child) => this.renderTree(child))}</>
           
       
        )}
        
 
       </>

    );
  };

  // Função para adicionar um novo filho ao nó clicado
 




  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      pessoa: {
        ...prevState.pessoa,
        [id]: value
      }
    }));
  }


  handleFileChange = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };


 TreeNode = ({ node, x, y }) => {
    return (
      <div
        style={{
          position: 'absolute',
          top: y,
          left: x,
          width: '110px',
          height: '160px',
          backgroundColor:'#fff',
          border: '1px solid #cacccc',
          textAlign: 'center',
          padding: '10px',
          borderRadius:'10px',
          boxSizing: 'border-box',
          filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
        }}
      >
        <div class="ft-user">
<img src={male} width={50} height={50}/>
       <div className='ncdd'>
         {this.Bandeira(node.ncdd)}
         </div>
        </div>
        
        <p>{node.label} {node.sobrenome}</p>
        <div>
          <strong style={{fontSize:14}}>{node.req}</strong>
        </div>




      </div>
    );
  };
  
 Tree = ({ nodes, parent, x, y, spacing, cjg,children }) => {
    const totalWidth = (nodes.length - 1) * spacing;
    const startX = x - totalWidth / 2;
  
    return (
      <>
        {nodes.map((node, index) => {
          const newX = startX + index * spacing;
          const newY = y + 200;
  
          return (
            <React.Fragment key={node.id}>
              {/* Linha que liga o nó atual ao nó pai */}
             
              {parent && <>
              
                {node.cjg ? <>
                
                  <div
                      style={{
                        position: 'absolute',
                        top: y+168,
                        left: x+130,
                        width: `80px`,
                        height: `114px`,
                        borderLeft: '2px solid #9ea1a1',
                        
                      }}
                    />
                
                </>:<>
              
                  <div
                      style={{
                        position: 'absolute',
                        top: y+168,
                        left: x+50,
                        width: `80px`,
                        height: `32px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
                
                </>}
           
              
              </>}
  
  
  
            


          
              <this.TreeNode node={node} x={newX} y={newY} />
        

     
         


             

              {/* Renderizar filhos do nó atual */}
           
              {node.cjg && (
              <>
                <this.Tree nodes={[node.cjg]} children={node.children}  cjg={true} x={newX + 150} y={newY-200} spacing={spacing / 2} />
                <div
                      style={{
                        position: 'absolute',
                        top: y+280,
                        left: x+110,
                        width: `40px`,
                        height: `1px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
          
      
           {node.children != undefined && node.children.length > 0 && <>
            
            
       


            

            
            <div
                      style={{
                        position: 'absolute',
                        top: y+280,
                        left: x+130,
                        width: `1px`,
                        height: `110px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
                        <div
                      style={{
                        position: 'absolute',
                        top: y+388,
                        left: x-20,
                        width: `150px`,
                        height: `1px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
           </>}
                     
              </>
              )}
  
              {node.children && (<>
                <this.Tree nodes={node.children} parent={node} x={newX - 150} y={newY+20} cjg={false} spacing={spacing / 2} />
            {node.cjg == undefined && <>
         
           {node.children != undefined && node.children.length > 0 && <>
            <div
                      style={{
                        position: 'absolute',
                        top: y+360,
                        left: x-20,
                        width: `80px`,
                        height: `30px`,
                        borderRight: '2px solid #9ea1a1',
                        borderBottom: '2px solid #9ea1a1',
                      }}
                    >


                  
                    </div>
           </>}
                     
                   
            
            </>}
             </>
              )}
  
            </React.Fragment>
          );
        })}
      </>
    );
  };
  
  handleWheel = (e) => {

      e.preventDefault();
      e.stopPropagation(); // Impede a propagação para outros elementos da página
  
      const scaleChange = e.deltaY < 0 ? 0.1 : -0.1; // Zoom in com scroll para cima, zoom out para baixo
      this.setState((prevState) => ({
        scale: Math.min(Math.max(prevState.scale + scaleChange, 0.5), 3), // Limita o zoom entre 0.5x e 3x
      }));
    
  };
  render() {
    var pessoa = this.state.pessoa;
    const { tree,novo,x,y,scale } = this.state;

    
    const isChecked = this.state.tree.length > 0 && this.state.tree[0].cjg ? true : false; // Verifica se existe `tree[0]` e `cjg`

    const isFlc = this.state.tree.length > 0 && this.state.tree[0].flc ? true : false; // Verifica se existe `tree[0]` e `cjg`


    return (<>
     <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
              this.props.navigateTo(-1)
          }}>
   <FontAwesomeIcon icon={faAngleLeft}/>Nova árvore</button>
          </div>
          </header>
    
        <div class="container-fluid">
    <div class="row area-app">

      <div class="col-12 col-md-6 full-height   d-flex justify-content-center" style={{background:"#f5f5f5"}}>
     
     
      <div class="container mt-4 mb-4">
    <div class="card mb-5">
      <div class="card-header">
      Família 
      </div>

      <div class="card-body add-usr">









{false? <>
<>
<div className='mb-3'>


<div class="card">
      <div class="card-header">
      Primeiro ancestral
      </div>
      <div class="card-body">







      <div class="form-group mb-3">
        
     <div className='add-img'>
<FontAwesomeIcon icon={faFile}/>
<input type='file'/>
     </div>
        </div>


<input type="text" id="descendantName" value={this.state.tree[0].label} placeholder="Nome completo:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].label = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>






<select id="descendantGender" value={this.state.tree[0].genero || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
this.setState((prevState) => ({
  tree: prevState.tree.map((item) => ({
    ...item,
   genero:e.target.value, // Adiciona o sobrenome
  })),
}));
 }}>
                   <option value="s">Genero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>

   


<select id="descendantRelation" value={this.state.tree[0].req || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
this.setState((prevState) => ({
  tree: prevState.tree.map((item) => ({
    ...item,
   req:e.target.value, // Adiciona o sobrenome
  })),
}));
 }} >              <option value="s">Denominação:</option>
                   <option value="FILHO (Requerente)">FILHO (Requerente)</option>
                   <option value="FILHOS (Menores)">FILHOS (Menores)</option>
                   <option value="PAI">PAI</option>
                   <option value="MÃE">MÃE</option>
                   <option value="AVO (a)">AVO (a)</option>
                   <option value="BISAVO (a)">BISAVO (a)</option>
                   <option value="TRISAVO (a)">TRISAVO (a)</option>
               </select>
        


               <div style={{marginTop:10}}>
<input type="text" id="descendantName" value={this.state.tree[0].lnac} placeholder="Local nascimento:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].lnac = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>

<input type="date" id="descendantName" value={this.state.tree[0].dnac} placeholder="Local nascimento:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].dnac = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>


<select id="descendantRelation" value={this.state.tree[0].ncdd || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
this.setState((prevState) => ({
  tree: prevState.tree.map((item) => ({
    ...item,
   ncdd:e.target.value, // Adiciona o sobrenome
  })),
}));
 }} >              <option value="s">Nacionalidade:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>

               </div>

<div>

</div>

<div className='mt-2 mb-2' style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <label>Falecido (a): </label> <input 
            type="checkbox" 
           style={{marginLeft:5}}
            checked={isFlc}
            onChange={(event)=>{
              const isChecked = event.target.checked;
              const updatedTree = [...this.state.tree];
          
              if (isChecked) {
                // Se marcado, adiciona `cjg: {}`
                updatedTree[0] = { ...updatedTree[0], flc: {} };
              } else {
                // Se desmarcado, remove `cjg`
                const { flc, ...rest } = updatedTree[0]; // Remove a chave `cjg`
                updatedTree[0] = rest;
              }
          
              this.setState({ tree: updatedTree });
            }} 
          />
               </div>

               {this.state.tree[0].flc != undefined && <div className='mt-2 mb-2'>
               
               
               
               
               
                <input type="text" id="spouseName" value={this.state.tree[0].flc.local} placeholder="Local de falecimento" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].flc.local = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>    
               
               

  <input type="date" id="spouseName" value={this.state.tree[0].flc.data} placeholder="Data do falecimento" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].flc.data = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>   




               
               </div>}

 {this.state.tree[0].cjg == undefined ? <>
<button className='btn btn-primary mt-2' onClick={(e)=>{

 const updatedTree = [...this.state.tree];


   // Se marcado, adiciona `cjg: {}`
   updatedTree[0] = { ...updatedTree[0], cjg: {} };
 

 this.setState({ tree: updatedTree });
}}>
Adicionar cônjuge
</button>
</> : <>
<div class="card mb-2 mt-3">
        
      <div class="card-header d-flex align-items-center justify-content-between">
           <span>Conjuguê</span>
           <button className='btn btn-danger' onClick={()=>{
  
            const updatedTree = [...this.state.tree];
        
    
              // Se desmarcado, remove `cjg`
              const { cjg, ...rest } = updatedTree[0]; // Remove a chave `cjg`
              updatedTree[0] = rest;
          
        
            this.setState({ tree: updatedTree });
           }}>
            <FontAwesomeIcon icon={faTrash}/>
           </button>
         
      </div>
        
      <div class="card-body">
      <input type="text" id="spouseName" value={this.state.tree[0].cjg.label} placeholder="Nome de solteira" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.label = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
      }}/>



<input type="text" id="spouseName" value={this.state.tree[0].cjg.nomec} placeholder="Nome de casada" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.nomec = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>



            <select id="spouseGender" value={this.state.tree[0].cjg.genero || 's'} onChange={(e)=>{
              if(e.target.value == "s") return;
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.genero = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}>
                <option value="s">Genero:</option>
                <option value="m">Masculino</option>
                <option value="f">Feminino</option>
            </select>

            <div style={{marginTop:10}}>
            
            <input type="text" id="spouseName" value={this.state.tree[0].cjg.lnac} placeholder="Local de nascimento:" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.lnac = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>



<input type="date" id="spouseName" value={this.state.tree[0].cjg.data} placeholder="Local de nascimento:" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.data = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>   


               
 <select id="descendantRelation" value={this.state.tree[0].cjg.ncdd || 's'} onChange={(e)=>{
if(e.target.value == "s") return;
const { tree, inputValue } = this.state;

// Clonando o array para não mutar o estado diretamente
const updatedTree = [...tree];

// Adicionando o novo item ao objeto cjg do primeiro índice
updatedTree[0].cjg.ncdd = e.target.value;

// Atualizando o estado
this.setState({ tree: updatedTree, inputValue: '' });
 }} >              <option value="s">Nacionalidade:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>
            </div>
      </div>
      </div>

</>}


            
          



     
      </div>
      <div class="card-footer d-flex justify-content-end">
    <button class="btn btn-primary">Salvar</button>
  </div>
      </div>

{tree[0].children.length == 0  && <>
  <div className='d-flex justify-content-center mt-3'>
<button className='btn btn-primary' onClick={()=>{
this.setState({addM:true})
}}>
  Adicionar
</button>
</div>
</>}


             
</div>

</>


{this.state.tree[0].children != undefined && tree[0].children.map((node) => this.renderTree(node))}




</>:<div className='d-flex justify-content-center align-items-center'>

<button className='btn btn-primary' onClick={(e)=>{
  
  e.target.classList.add('load')
  
  fetch(`${apiUrl}/criar-arvore/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getCookie('auth_token')}`,
    },
    body: JSON.stringify({
 
    }),
  })
    .then(response => {
     
      
     

if(response.status == 200){
response.json().then(data=>{
  this.props.navigateTo(`/arvore/${data.token}/edit`)
})
}

}).catch(err=>{
  toast.error("Falha de comunicação com servidor!")
})




  
  
  
 
}}>
  Criar
</button>


</div>}





      </div>
    </div>
  </div>

   
      </div>

      <div class="col-12 col-md-6"    onWheel={this.handleWheel}      onMouseDown={this.handleMouseDown}  style={{background:'#dddfdf'}}>
     
      <div className='area-avr' style={{ position: 'relative', width: '100%' }}>
      
<div  style={{
            position: 'absolute',
            left: x,
            top: y,
            transform: `scale(${scale})`, // Aplica o zoom
            cursor: 'grab',
          }}>




      <this.Tree  nodes={this.state.tree} parent={null} x={350} y={-100} spacing={200} />
    
    </div>
    </div>
      </div>
    </div>
  </div>


  <StaticDialog
        isOpen={this.state.ft1}
        title="Adicionar imagem"
        onAfterClose={(result) => {
 this.setState({ft1:false})
          // do something with dialog result
        }}
    ></StaticDialog>





<StaticDialog
        isOpen={this.state.addno != undefined}
        title="Adicionar descendente"
        onAfterClose={(result) => {
 this.setState({addno:undefined})
          // do something with dialog result
        }}
    >


<div className='m-btn'>

<button className='btn btn-primary' onClick={()=>{
  this.addNode(this.state.addno)
  this.setState({addno:undefined})
}}>

<FontAwesomeIcon icon={faSave}/> Salvar

</button>

</div>




    </StaticDialog>



  <StaticDialog
        isOpen={this.state.addM}
        title="Adicionar descendente"
        onAfterClose={(result) => {
 this.setState({addM:false})
          // do something with dialog result
        }}
    >

<div style={{
  display:'table',
  margin:'auto',
  marginTop:15

}}>



<input type="text" id="descendantName" value={novo.label} placeholder="Nome do Descendente" onChange={(e)=>{
 this.setState((prevState) => ({
  novo: {
    ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
    label:e.target.value
  }
}));
}}/>

 <select id="descendantGender" onChange={(e)=>{
  if(e.target.value == "s") return;
  this.setState((prevState) => ({
    novo: {
      ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
      genero:e.target.value
    }
  }));
 }}>
                   <option value="s">Gênero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>
               
<select id="descendantRelation" onChange={(e)=>{
    if(e.target.value == "s") return;
 this.setState((prevState) => ({
  novo: {
    ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
    req:e.target.value
  }
}));
 }} >              <option value="s">Denominação</option>
                   <option value="FILHO (Requerente)">FILHO (Requerente)</option>
                   <option value="FILHOS (Menores)">FILHOS (Menores)</option>
                   <option value="PAI">PAI</option>
                   <option value="MÃE">MÃE</option>
                   <option value="AVO (a)">AVO (a)</option>
                   <option value="BISAVO (a)">BISAVO (a)</option>
                   <option value="TRISAVO (a)">TRISAVO (a)</option>
               </select>
              
              


</div>

<div className='m-btn'>

<button className='btn btn-primary' onClick={()=>{
var data = this.state.novo;
const newTree = [...this.state.tree];

// Adiciona um filho ao índice 0
newTree[0] = {
  ...newTree[0],
  children: [...(newTree[0].children || []), {
    id:this.GerToken(4),
    label:data.label,
    req:data.req,
    genero:data.genero
  }],

};

// Atualiza o estado com a nova árvore
this.setState({ tree: newTree,novo:{},  addM:false});
}}>
<FontAwesomeIcon icon={faSave} color='#fff'/>
Salvar
</button>

</div>

</StaticDialog>



        </>
    );
  }
}

export default NovaArvore;