import React, { Component } from 'react';
import { apiUrl } from '../../comps/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faPen, faSave, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import doc from "../../images/doc.png"
import Tree from 'react-d3-tree';
import ex from "../../images/ex.webp"
import pd from "../../images/pdf.png"
import { toast } from 'react-toastify';



import male from "../../images/male.png"
import female from "../../images/female.png"

import br from "../../images/br.png"
import pt from "../../images/pt.png"
import es from "../../images/es.png"
import it from "../../images/it.png"
import po from "../../images/po.png"


class TreePrev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{},
      loader:true,
      falec:false,
      tree:[]
    };
  }

componentDidMount(){
    document.title = "Árvore"
    const rootElement = document.getElementById('root');
if(rootElement){
  rootElement.classList.add("fixa")
}

    fetch(`${apiUrl}/arvore/show/`+window.location.pathname.split("/")[2], {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // outras headers, se necessário
        },
      })
        .then(response => {
if(response.status == 200){
response.json().then(data=>{
 
    this.setState({
        data,
        loader:false,
        tree:data.tree
    })
})
}else{

}
        })

    
}

componentWillUnmount() {
  const rootElement = document.getElementById('root');
  if(rootElement){
    rootElement.classList.remove("fixa")
  }
}

 capitalizeFirstLetter(string) {
  if(string == null){
    return '';
  }
  return 'família ' +string.charAt(0).toUpperCase() + string.slice(1);
}
handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [id]: value
      }
    }));
  }



   
  


  handleFileChange = (e) => {
    const file = e.target.files[0];
    
    // Verifica se o arquivo é um PDF
    if (file && file.type !== 'application/pdf') {
      toast.warn("Apenas arquivos PDF são permitidos!")
      e.target.value = ''
      return;
    }

    // Se for um PDF válido, atualiza o estado e limpa o erro
    this.setState({
      [e.target.name]: file,
      error: '', // Limpa qualquer mensagem de erro anterior
    });
  };

  Bandeira(e){
    switch(e){
      case 'Brasil':
      return <img src={br}/>
  
      case 'Itália':
      return <img src={it}/>
  
      case 'Portugal':
      return <img src={pt}/>
  
      case 'Espanha':
      return <img src={es}/>
  
      case 'Polonia':
      return <img src={po}/>
      
  
      
  
    }
  }


  TreeNode = ({ node, x, y ,cjg}) => {
    if(cjg){
     return (
       <div
         style={{
           position: 'absolute',
           top: y,
           left: x,
           width: '110px',
           height: '160px',
           backgroundColor:'#fff',
           border: `2px solid ${node.code == this.state.selected ? 'blue': '#ccc'}`,
           textAlign: 'center',
           padding: '10px',
           borderRadius:'10px',
           boxSizing: 'border-box',
           filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
         }} onClick={()=>{
         this.setState({selected:node.code})
         }}
       >
         <div class="ft-user">
          
         {node.genero === undefined ? 'Sem foto' : node.genero === "f" ? <>
         <img src={female} className='user'/>
         </>: <>
         <img src={male} className='user'/>
         </>}
         
         
          <div className='ncdd'>
          {this.Bandeira(node.ncdd)}
          </div>
         </div>
         
 
 
         <p>{node.ncasada}</p>
 
      
 
 
 
 
       </div>
     );
    }else{
     return (
       <div
         style={{
           position: 'absolute',
           top: y,
           left: x,
           width: '110px',
           height: '160px',
           backgroundColor:'#fff',
           border: `2px solid ${node.id == this.state.selected ? 'blue': '#ccc'}`,
           textAlign: 'center',
           padding: '10px',
           borderRadius:'10px',
           boxSizing: 'border-box',
           filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
         }}
         onClick={()=>{
           console.log(node)
           this.setState({selected:node.id})
           }}
       >
         <div class="ft-user">
          
         {node.genero === undefined ? 'Sem foto' : node.genero === "f" ? <>
         <img src={female} className='user'/>
         </>: <>
         <img src={male} className='user'/>
         </>}
         
         
          <div className='ncdd'>
          {this.Bandeira(node.ncdd)}
          </div>
         </div>
         
 
 
         <p>{node.label} {node.sobrenome}</p>
 
         <div>
           <strong style={{fontSize:14}}>{node.req ? <>{node.req}</> : <>DANTE CAUSA</>}</strong>
         </div>
 
 
 
 
       </div>
     );
    }
 
 
   };
   
  Tree = ({ nodes, parent, x, y, spacing, cjg,children }) => {
     const totalWidth = (nodes.length - 1) * spacing;
     const startX = x - totalWidth / 2;
   
     return (
       <>
         {nodes.map((node, index) => {
           const newX = startX + index * spacing;
           const newY = y + 200;
   
           return (
             <React.Fragment key={node.id}>
               {/* Linha que liga o nó atual ao nó pai */}
              
               {parent && <>
               
                 {node.cjg ? <>
                 
                   <div
                       style={{
                         position: 'absolute',
                         top: y+168,
                         left: x+130,
                         width: `80px`,
                         height: `114px`,
                         borderLeft: '2px solid #9ea1a1',
                         
                       }}
                     />
                 
                 </>:<>
               
                   <div
                       style={{
                         position: 'absolute',
                         top: y+168,
                         left: x+50,
                         width: `80px`,
                         height: `32px`,
                         borderLeft: '2px solid #9ea1a1',
                         borderTop: '2px solid #9ea1a1',
                       }}
                     />
                 
                 </>}
            
               
               </>}
   
   
   
             
 
 
           
               <this.TreeNode node={node} cjg={cjg} x={newX} y={newY} />
         
 
      
          
 
 
              
 
               {/* Renderizar filhos do nó atual */}
            
               {node.cjg && (
               <>
                 <this.Tree nodes={[node.cjg]} children={node.children}  cjg={true} x={newX + 150} y={newY-200} spacing={spacing / 2} />
                <div
                       style={{
                         position: 'absolute',
                         top: y+280,
                         left: x+110,
                         width: `40px`,
                         height: `1px`,
                         borderLeft: '2px solid #9ea1a1',
                         borderTop: '2px solid #9ea1a1',
                       }}
                     />
           
       
            {node.children != undefined && node.children.length > 0 && <>
             
             
        
 
 
             
 
             
             <div
                       style={{
                         position: 'absolute',
                         top: y+280,
                         left: x+130,
                         width: `1px`,
                         height: `110px`,
                         borderLeft: '2px solid #9ea1a1',
                         borderTop: '2px solid #9ea1a1',
                       }}
                     />
                         <div
                       style={{
                         position: 'absolute',
                         top: y+388,
                         left: x-20,
                         width: `150px`,
                         height: `1px`,
                         borderLeft: '2px solid #9ea1a1',
                         borderTop: '2px solid #9ea1a1',
                       }}
                     />
            </>}
                      
               </>
               )}
   
               {node.children && (<>
                 <this.Tree nodes={node.children} parent={node} x={newX - 150} y={newY+20} cjg={false} spacing={spacing / 2} />
             {node.cjg == undefined && <>
          
            {node.children != undefined && node.children.length > 0 && <>
             <div
                       style={{
                         position: 'absolute',
                         top: y+360,
                         left: x-20,
                         width: `80px`,
                         height: `30px`,
                         borderRight: '2px solid #9ea1a1',
                         borderBottom: '2px solid #9ea1a1',
                       }}
                     >
 
 
                   
                     </div>
            </>}
                      
                    
             
             </>}
              </>
               )}
   
             </React.Fragment>
           );
         })}
       </>
     );
   };



  render() {
    if(this.state.loader){
        return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
          <div className='loader'>
          <div class="spinner"></div>
          </div>
          </div>)
      }
      if(this.state.data.length ==0){
        return(<>
        <div class="p404 doc">
   <img src={doc}/>
   <p>Desculpe, Esse documento não foi encontrado.</p>
   <button  class="btn btn-primary" onClick={()=>{
    this.props.navigateTo(-1)
   }}>
     <FontAwesomeIcon icon={faArrowLeft} />
      Voltar
   </button>
</div>

        </>)
      }

      var d = this.state.data;
      var tree = this.state.tree;

      return (<>
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
              this.props.navigateTo(-1)
          }}>
   <FontAwesomeIcon icon={faAngleLeft}/> Árvore {this.capitalizeFirstLetter(this.state.tree.sobrenome)}</button>
          </div>
          </header>
      
          <div class="container-fluid full-height">

      <div  style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:"#dddfdf",
        height:'100vh',
        
        }}>
     
     
      <div style={{display:'flex',alignItems:'center'}}>
      <button className='btn btn-primary' onClick={()=>{
    this.props.navigateTo(`/arvore/${document.location.pathname.split("/")[2]}/edit`)
}}><FontAwesomeIcon icon={faPen}/> Editar</button>
        <a href={`${apiUrl}/arvore/${window.location.pathname.split("/")[2]}/baixar/pdf`} style={{marginLeft:25}} target='_blank'>
<img src={pd} style={{width:50,height:50,cursor:'pointer'}} />
</a>
     
      </div>
       


      </div>
        

  



     

  </div>
    
  
          </>
      );
  }
}

export default TreePrev;
