import { faAngleLeft, faDownload, faEye, faFile, faImage, faMagnifyingGlass, faPen, faPlus, faSave, faShare, faShareAlt, faTrash, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Children, Component } from 'react';
import { json, Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { apiUrl } from '../../comps/Config';
import { StaticDialog } from 'react-st-modal';

import male from "../../images/male.png"
import female from "../../images/female.png"

import casa from "../../images/casa.png"

import br from "../../images/br.png"
import pt from "../../images/pt.png"
import es from "../../images/es.png"
import it from "../../images/it.png"
import po from "../../images/po.png"

class EdtArvore extends Component {
  constructor(props) {
    super(props);
    this.itemRefs = [];
    this.state = {

      tree:[],
      ndc:'',
      sex:'',
      tip:'',
      addM:false,
      ft1:false,
      novo:{},
      addno:undefined,
      isDragging: false,
      x: 0,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      scale: 1, 
      node:undefined,
      error:false,
      loader:true,
      selected:0

    }


  
  }
 GerToken(tamanho) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let codigo = '';
    
    for (let i = 0; i < tamanho; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      codigo += caracteres[indiceAleatorio];
    }
    
    return codigo;
  }
  
  handleMouseDown = (e) => {
    this.setState({
      isDragging: true,
      offsetX: e.clientX - this.state.x,
      offsetY: e.clientY - this.state.y,
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = (e) => {
    if (this.state.isDragging) {
      this.setState({
        x: e.clientX - this.state.offsetX,
        y: e.clientY - this.state.offsetY,
      });
    }
  };

  handleMouseUp = () => {
    this.setState({ isDragging: false });
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };





  componentDidMount(){
document.title = "Editar árvore"
const rootElement = document.getElementById('root');
if(rootElement){
  rootElement.classList.add("fixa")
}


  
fetch(`${apiUrl}/listar-arvore/${window.location.pathname.split("/")[2]}`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  }
})
  .then(response => {
    if(response.status == 404){
      this.setState({error:true,loader:false})
      return;
    }



if(response.status == 200){
  response.json().then(data=>{
    this.setState({tree:data,loader:false})
    })
}
  }).catch(err=>{
    toast.error("Falha de comunicação com o servidor!")
  })




  }

  componentWillUnmount() {
    const rootElement = document.getElementById('root');
    if(rootElement){
      rootElement.classList.remove("fixa")
    }
}




  lockScroll = () => {
    document.body.style.overflow = 'hidden';
}


  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedTree = [...this.state.tree];

    if (isChecked) {
      // Se marcado, adiciona `cjg: {}`
      updatedTree[0] = { ...updatedTree[0], cjg: {} };
    } else {
      // Se desmarcado, remove `cjg`
      const { cjg, ...rest } = updatedTree[0]; // Remove a chave `cjg`
      updatedTree[0] = rest;
    }

    this.setState({ tree: updatedTree });
  };

  updateNode = (node, key, value) => {
    // Atualize o valor do nó
    node[key] = value;
    
    // Defina o novo estado com a árvore atualizada
    this.setState({ tree: this.state.tree });
  };

  FindByReq(data, reqValue) {
    let result = [];

    function search(children, ancestors = []) {
        if (!Array.isArray(children)) return; // Verifica se `children` é um array

        for (const item of children) {
            // Adiciona o item atual na lista de ancestrais temporária
            const currentAncestors = [...ancestors, item];

            // Verifica se o item corresponde ao `reqValue`
            if (item.req === reqValue) {
                // Traz os dois itens anteriores, se existirem
                const startIndex = Math.max(0, currentAncestors.length - 3);
                result = currentAncestors.slice(startIndex);
                return; // Encerra a busca
            }

            // Busca recursivamente nos filhos
            if (Array.isArray(item.children) && item.children.length > 0) {
                search(item.children, currentAncestors);
            }
        }
    }

    search(data);
    return result;
}


Bandeira(e){
  switch(e){
    case 'Brasil':
    return <img src={br}/>

    case 'Itália':
    return <img src={it}/>

    case 'Portugal':
    return <img src={pt}/>

    case 'Espanha':
    return <img src={es}/>

    case 'Polonia':
    return <img src={po}/>
    

    

  }
}


removeNode = (id) => {
  const removeNodeRecursively = (nodes) => {
    return nodes.filter(node => {
      if (node.id === id) return false;
      if (node.children) {
        node.children = removeNodeRecursively(node.children);
      }
      return true;
    });
  };
  
  const updatedTree = removeNodeRecursively(this.state.tree);
  this.setState({ tree: updatedTree });
};


addNode = (id,data) => {
   
  const addChildrenRecursively = (nodes) => {
    return nodes.map(node => {
      if (node.id === id) {
        // Add an empty children array if it doesn't already exist
        if (!node.children) {
          node.children = [{
            id:data.id,label:data.label,
            req:data.req,
            genero:data.genero,
            nmr:data.nmr,
            ncdd:'Brasil'
          }];
        }
      } else if (node.children) {
        node.children = addChildrenRecursively(node.children);
      }
      return node;
    });
  };

  const updatedTree = addChildrenRecursively(this.state.tree);
  this.setState({ tree: updatedTree });
};



Parentes(req){




  var array = this.FindByReq(this.state.tree,req)
  let avos = {
      avos_paternos: {},
      avos_maternos: {},
      pais:{}
    };
  
  var acm = (array[array.length - 2])
   
if(array.length == 2){
  
      
    avos.pais.pai = acm?.label ? acm.label + ' ' + (acm.sobrenome || '') : '';
    avos.pais.mae = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : '';
  
    avos.avos_paternos.avoh = acm?.pai ? acm.pai + ' ' + (acm.spai || '') : '';
    avos.avos_paternos.avom = acm?.mae ? acm.mae + ' ' + (acm.smae || '') : '';
  
    avos.avos_maternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
    avos.avos_maternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
    
  
  }else if(array.length == 3){
  if(acm.genero == "m"){
  
  
    var prn = (array[array.length - 3])
    var mtn = (array[array.length - 2])
    
  
   
  
    avos.pais.pai = mtn?.label ? mtn.label + ' ' + (mtn.sobrenome || '') : '';
    avos.pais.mae = mtn.cjg ? (mtn.cjg.label ? mtn.cjg.label + ' ' + mtn.cjg.sobrenome : mtn.cjg.sobrenome || '') : '';
  
  
  
  
    
   if(prn.cjg != undefined && prn.cjg.dcasada !=null && prn.cjg.dcasada != '' ){
    avos.avos_paternos.avoh = prn.cjg.dcasada
   }else{
    avos.avos_paternos.avoh = prn?.label ? prn.label + ' ' + (prn.sobrenome || '') : '';
   }
  
  
  
    avos.avos_paternos.avom = prn.cjg ? (prn.cjg.label ? prn.cjg.label + ' ' + prn.cjg.sobrenome : prn.cjg.sobrenome || '') : '';
    
  
  
    avos.avos_maternos.avoh = mtn.cjg ? (mtn.cjg.pai ? mtn.cjg.pai + ' ' + mtn.cjg.spai : mtn.cjg.spai || '') : '';
    avos.avos_maternos.avom = mtn.cjg ? (mtn.cjg.mae ? mtn.cjg.mae + ' ' + mtn.cjg.smae : mtn.cjg.smae || '') : '';
  
  
  
  
  
  }else{
  
    var acm = (array[array.length - 3])
  
    var prn = (array[array.length - 2])
  
  if(prn.cjg != undefined && prn.cjg.dcasada !=null && prn.cjg.dcasada != '' ){
    avos.pais.mae = prn.cjg.dcasada
  }else{
    avos.pais.mae = prn?.label ? prn.label + ' ' + (prn.sobrenome || '') : '';
  }
  
  
  
    avos.pais.pai = prn.cjg ? (prn.cjg.label ? prn.cjg.label + ' ' + prn.cjg.sobrenome : prn.cjg.sobrenome || '') : '';
  
  
    
    
    if(prn.genero == "m"){
  
    avos.avos_maternos.avoh = "a1";
    avos.avos_maternos.avom = "a2";
    
  
  
      avos.avos_paternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
      avos.avos_paternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
    
    
    }else{

      avos.avos_maternos.avoh = acm?.label ? acm.label + ' ' + (acm.sobrenome || '') : '';
      avos.avos_maternos.avom = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : '';
    
  
      avos.avos_paternos.avoh = prn.cjg ? (prn.cjg.pai ? prn.cjg.pai + ' ' + prn.cjg.spai : prn.cjg.spai || '') : '';
      avos.avos_paternos.avom = prn.cjg ? (prn.cjg.mae ? prn.cjg.mae + ' ' + prn.cjg.smae : prn.cjg.smae || '') : '';
                 
    
     }
     
  
  
  }

}



  

  return(<>
    <div className='pais mt-3'>
      <label>Pais</label>
      <div>
      <input type="text" value={avos.pais.pai} disabled/>
      <input type="text" value={avos.pais.mae} disabled/>
      </div>
    </div>
    
    
    <div className='pais mt-3'>
      <label>Avós paternos</label>
      <div>
      <input type="text" value={avos.avos_paternos.avoh} disabled/>
      <input type="text" value={avos.avos_paternos.avom} disabled/>
      </div>
    </div>
    
    
    <div className='pais mt-3'>
      <label>Avós maternos</label>
      <div>
      <input type="text" value={avos.avos_maternos.avoh} disabled/>
      <input type="text" value={avos.avos_maternos.avom} disabled/>
      </div>
    </div>
    
    
    </>)

}


scrollToItem = (id) => {
  
};


  renderTree = (node) => {
    return (
      
   
       <>
           <div class="card mb-3" style={{border:`solid 2px ${this.state.selected == node.id ? 'blue': '#d2d2d2'}`}}
           ref={node.id} 
           id={node.id} 
          onClick={()=>{




         

            var array = this.FindByReq(this.state.tree,'node.req')
            console.log(array)
            let avos = {
                avos_paternos: {},
                avos_maternos: {},
                pais:{}
              };
            var eu = array[array.length - 1]
            var acm = (array[array.length - 2])

           if(array.length == 2){

            avos.pais.pai = acm?.label ? acm.label + ' ' + (acm.sobrenome || '') : '';
            avos.pais.mae = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : '';
          
            if(eu.genero == "f"){
               avos.avos_paternos.avoh = acm?.pai ? acm.pai + ' ' + (acm.spai || '') : '';
               avos.avos_paternos.avom = acm?.mae ? acm.mae + ' ' + (acm.smae || '') : '';

              avos.avos_maternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
              avos.avos_maternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
              
            }else{
              avos.avos_paternos.avoh = acm?.pai ? acm.pai + ' ' + (acm.spai || '') : '';
              avos.avos_paternos.avom = acm?.mae ? acm.mae + ' ' + (acm.smae || '') : '';

             avos.avos_maternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
             avos.avos_maternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
             
            }
                
             
            
            
              }else if(array.length == 3){
            if(acm.genero == "m"){
            
            
              var prn = (array[array.length - 3])
              var mtn = (array[array.length - 2])
              
            
             
            
              avos.pais.pai = mtn?.label ? mtn.label + ' ' + (mtn.sobrenome || '') : '';
              avos.pais.mae = mtn.cjg ? (mtn.cjg.label ? mtn.cjg.label + ' ' + mtn.cjg.sobrenome : mtn.cjg.sobrenome || '') : '';
            
            
            
            
              
             if(prn.cjg != undefined && prn.cjg.dcasada !=null && prn.cjg.dcasada != '' ){
              avos.avos_paternos.avoh = prn.cjg.dcasada
             }else{
              avos.avos_paternos.avoh = prn?.label ? prn.label + ' ' + (prn.sobrenome || '') : '';
             }
            
            
            
              avos.avos_paternos.avom = prn.cjg ? (prn.cjg.label ? prn.cjg.label + ' ' + prn.cjg.sobrenome : prn.cjg.sobrenome || '') : '';
              avos.avos_maternos.avoh = mtn.cjg ? (mtn.cjg.pai ? mtn.cjg.pai + ' ' + mtn.cjg.spai : mtn.cjg.spai || '') : '';
              avos.avos_maternos.avom = mtn.cjg ? (mtn.cjg.mae ? mtn.cjg.mae + ' ' + mtn.cjg.smae : mtn.cjg.smae || '') : '';
            
            
            
            
            
            }else{
            
              var acm = (array[array.length - 3])
            
              var prn = (array[array.length - 2])
            
            if(prn.cjg != undefined && prn.cjg.dcasada !=null && prn.cjg.dcasada != '' ){
              avos.pais.mae = prn.cjg.dcasada
            }else{
              avos.pais.mae = prn?.label ? prn.label + ' ' + (prn.sobrenome || '') : '';
            }
            
            
            
              avos.pais.pai = prn.cjg ? (prn.cjg.label ? prn.cjg.label + ' ' + prn.cjg.sobrenome : prn.cjg.sobrenome || '') : '';
            
            
              
              
              if(prn.genero == "m"){
            
              avos.avos_maternos.avoh = "a1";
              avos.avos_maternos.avom = "a2";
              
            
            
                avos.avos_paternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
                avos.avos_paternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
              
              
              }else{
    
                avos.avos_maternos.avoh = acm?.label ? acm.label + ' ' + (acm.sobrenome || '') : '';
                avos.avos_maternos.avom = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : '';
              
            
                avos.avos_paternos.avoh = prn.cjg ? (prn.cjg.pai ? prn.cjg.pai + ' ' + prn.cjg.spai : prn.cjg.spai || '') : '';
                avos.avos_paternos.avom = prn.cjg ? (prn.cjg.mae ? prn.cjg.mae + ' ' + prn.cjg.smae : prn.cjg.smae || '') : '';
                           
              
               }
               
            
            
            }

          }


            
              console.log(avos)



      
      this.setState({selected:node.id})
           }}>
        
      <div class="card-header d-flex align-items-center justify-content-between">
           <strong><span>{node.nmr} - {node.req ? node.req+'(a)' : 'Novo descedente'}</span></strong>


           <button className='btn btn-danger' onClick={() => {


this.setState({trash:node.id})






           }}>
            <FontAwesomeIcon icon={faTrash}/>
           </button>
      </div>
        
      <div class="card-body">



     













<select id="descendantRelation"  onBlur={()=>this.updateMember(node)} style={{marginTop:10,display:'none'}} value={node.req || 's'} onChange={(e)=>{

this.updateNode(node, 'req', e.target.value)
  
 }} >              <option value="s">Denominação:</option>
  <option value="Filho">Filho(a)</option>
  <option value="Neto">Neto(a)</option>
  <option value="Bisneto">Bisneto(a)</option>
  <option value="Trineto">Trineto(a)</option>
  <option value="Tetraneto(a) (Requerente)">Tetraneto(a) (Requerente)</option>
  <option value="Tetraneto(a) (Menores)">Tetraneto(a) (Menores)</option>
   </select>


   <div className='pais'>
<div>
<input type="text"  onBlur={()=>this.updateMember(node)} value={node.label} placeholder="Nome do Descendente" onChange={(e)=>{
this.updateNode(node, 'label', e.target.value)
}}/>


<input type="text"  onBlur={()=>this.updateMember(node)} value={node.sobrenome} placeholder="Sobrenome:" onChange={(e)=>{
this.updateNode(node, 'sobrenome', e.target.value)
}}/>
</div>

  </div> 








               
 

              



<div>

</div>



<div className='mt-3 mb-3'>
<select   onBlur={()=>this.updateMember(node)} value={node.genero || 's'} onChange={(e)=>{
this.updateNode(node, 'genero', e.target.value)
 }}>
                   <option value="s">Gênero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>
<input type="text"  
style={{borderLeft:0}}
onBlur={()=>this.updateMember(node)} value={node.lnac} placeholder="Local de nascimento" onChange={(e)=>{
this.updateNode(node, 'lnac', e.target.value)
}}/>

<select id="descendantRelation" style={{borderLeft:0}} onBlur={()=>this.updateMember(node)} value={node.ncdd || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
  this.updateNode(node, 'ncdd', e.target.value)
 }} >              <option value="s">País:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>

<input type="date" style={{borderLeft:0}}  value={node.dnac} onBlur={()=>this.updateMember(node)} placeholder="Local de nascimento" onChange={(e)=>{
this.updateNode(node, 'dnac', e.target.value)
}}/>





</div>

<div className='mt-3'>
{this.Parentes(node.req)}
</div>










  {node.cjg == undefined ? <>
<button className='btn btn-primary mt-2' onClick={(e)=>{
 

  e.target.classList.add('load')
  var g = node.genero == "m" ? "f" : "m";
  fetch(`${apiUrl}/adicionar-conjugue/${node.id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({tipo:'c',g}),
  })
    .then(response => {
      e.target.classList.remove('load')
      if(response.status == 200){
  
  response.json().then(data=>{
    node['cjg'] = {code:data.code,tipo:'c',genero:g};
   this.setState({ tree: this.state.tree })
    toast.success("Cônjugue adicionado")
  })
  
  
  
  
      }
  
  
    }).catch(err=>{
      toast.error("Falha de comunicação com o srvidor!")
    })
  
  
  
  
  
  




  

}}>
Adicionar casamento
</button>
<button className='btn btn-primary mt-2' style={{marginLeft:10}} onClick={(e)=>{
 

 e.target.classList.add('load')
 var g = node.genero == "m" ? "f" : "m";
 fetch(`${apiUrl}/adicionar-conjugue/${node.id}`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
     // outras headers, se necessário
   },
   body: JSON.stringify({tipo:'u',g}),
 })
   .then(response => {
     e.target.classList.remove('load')
     if(response.status == 200){
 
 response.json().then(data=>{
   node['cjg'] = {code:data.code,tipo:'u',genero:g};
  this.setState({ tree: this.state.tree })
   toast.success("Cônjugue adicionado")
 })
 
 
 
 
     }
 
 
   }).catch(err=>{
     toast.error("Falha de comunicação com o srvidor!")
   })
 
 
 
 
 
 




 

}}>
Adicionar união
</button>
</> : <>

<br/>
{node.cjg.type == "u" && <>
  <label><strong>Casamento</strong></label>
<div className='mt-2'>


<input type="text" id="spouseName"  value={node['cjg']['lcasa']}
onBlur={(e)=>{
  var i = (e.target.value)

if(i == ""){
  toast.warn("Informe a data do casamento")
  return
}

if(node.dnac == null){
  toast.warn("Iforme a data de nascimento do requerente")
  return;
}

if(new Date(e.target.value) <= new Date(node.dnac)){
  e.target.value = null;
  toast.warn("A data do casamento tem que ser posterior a data de nascimento")
  return;
}
  this.updateConj(node.cjg)
}
}
placeholder="Digite:"

onChange={(e)=>{
  node['cjg']['lcasa'] = e.target.value   
  this.setState({ tree: this.state.tree });         
}} disabled={node.cjg.tipo == "u" ? true : false}/>

-aqui
<input type="date" id="spouseName" style={{borderLeft:0}} disabled={node.cjg.tipo == "u" ? true : false} value={node['cjg']['dcasa']}
onBlur={()=>this.updateConj(node.cjg)}
placeholder="Data do casamento:" onChange={(e)=>{
node['cjg']['dcasa'] = e.target.value   
this.setState({ tree: this.state.tree });      
      }}/>


<select id="descendantRelation" 
disabled={node.cjg.tipo == "u" ? true : false}
style={{borderLeft:0}}
onBlur={()=>this.updateMember(node)} value={node.bcasa || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
  this.updateNode(node, 'bcasa', e.target.value)
 }} >              <option value="s">País:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>

      
</div>
</>}







<div class="card mb-2 mt-3">
        
      <div class="card-header d-flex align-items-center justify-content-between">
      {node.cjg.tipo == "c" ? 'Dados do cônjuge' : 'Dados do companheiro (a)'}
           <button className='btn btn-danger' onClick={(e)=>{
  

  e.target.classList.add('load')

  fetch(`${apiUrl}/remover-conjugue/${node.cjg.code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({}),
  })
    .then(response => {
      e.target.classList.remove('load')

      if(response.status == 200){
        delete node['cjg']
        this.setState({ tree: this.state.tree });
        toast.success("Cônjugue removido!")
      }
  
  
    }).catch(err=>{
      toast.error("Falha de comunicação com o srvidor!")
    })
  





           }}>
            <FontAwesomeIcon icon={faTrash}/>
           </button>
         
      </div>
        
      <div class="card-body">
    


      <div className='pais'>
<div>


<input type="text" id="spouseName"

onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['label']} placeholder="Nome do cônjugue:" onChange={(e)=>{
                   node['cjg']['label'] = e.target.value;
                   this.setState({ tree: this.state.tree });
}}/> 



<input type="text" id="spouseName"

onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['sobrenome']} placeholder="Sobreome do cônjugue:" onChange={(e)=>{
                   node['cjg']['sobrenome'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/> 





</div>
</div>
   







            <div style={{marginTop:10}}>
            
            <select id="spouseGender"
                onBlur={()=>this.updateConj(node.cjg)}
            value={node['cjg']['genero'] || 's'} onChange={(e)=>{
              if(e.target.value == "s") return;
              node['cjg']['genero'] = e.target.value;
             

              this.setState({ tree: this.state.tree });    
                }}>
                <option value="s">Genero:</option>
                <option value="m">Masculino</option>
                <option value="f">Feminino</option>
            </select>
            <input type="text" id="spouseName" 
            
            onBlur={()=>this.updateConj(node.cjg)}
            
            value={node['cjg']['lnac']} placeholder="Local de nascimento:" onChange={(e)=>{
                     node['cjg']['lnac'] = e.target.value;
                     this.setState({ tree: this.state.tree });          
                }}/>

<select id="descendantRelation"
     onBlur={()=>this.updateConj(node.cjg)}
 value={node['cjg']['ncdd'] || 's'} onChange={(e)=>{
if(e.target.value == "s") return;
node['cjg']['ncdd'] = e.target.value;
this.setState({ tree: this.state.tree });
 }} >              <option value="s">País:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>




<input type="date" id="spouseName"
onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['dnac']} placeholder="Local de nascimento:" onChange={(e)=>{
                   node['cjg']['dnac'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/>   


               

            </div>
            <div className='mt-3 pais mb-3'>
              <label>Pais:</label>
              <div>
              <input type="text" id="spouseName"
onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['pai']} placeholder="Nome do pai:" onChange={(e)=>{
                   node['cjg']['pai'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/> 


<input type="text" id="spouseName"
   style={{borderLeft:0}}
onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['spai']} placeholder="Sobrenome do pai:" onChange={(e)=>{
                   node['cjg']['spai'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/>

              </div>


<div className='mt-2 mb-2'>
              <input type="text" id="spouseName"
onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['mae']} placeholder="Nome da mãe:" onChange={(e)=>{
                   node['cjg']['mae'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/> 


<input type="text" id="spouseName"
   style={{borderLeft:0}}
onBlur={()=>this.updateConj(node.cjg)}
value={node['cjg']['smae']} placeholder="Nome da mãe:" onChange={(e)=>{
                   node['cjg']['smae'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}/> 




              </div>



            </div>

            <div>
            <label>
          <strong>Casamento</strong></label>

{node.genero == "m" ? <>
  <div className='mt-2'>


<input type="text" id="spouseName"  value={node['cjg']['lcasa']}
onBlur={(e)=>{
  var i = (e.target.value)

if(i == ""){
  toast.warn("Informe a data do casamento")
  return
}

if(node.dnac == null){
  toast.warn("Iforme a data de nascimento do requerente")
  return;
}

if(new Date(e.target.value) <= new Date(node.dnac)){
  e.target.value = null;
  toast.warn("A data do casamento tem que ser posterior a data de nascimento")
  return;
}
  this.updateConj(node.cjg)
}
}
placeholder="local de casamento:"

onChange={(e)=>{
  node['cjg']['lcasa'] = e.target.value   
  this.setState({ tree: this.state.tree });         
}} disabled={node.cjg.tipo == "u" ? true : false}/>

<select id="descendantRelation" 
disabled={node.cjg.tipo == "u" ? true : false}
style={{borderLeft:0}}
onBlur={()=>this.updateMember(node)} value={node.bcasa || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
  this.updateNode(node, 'bcasa', e.target.value)
 }} >              <option value="s">País:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>

<input type="date" id="spouseName" style={{borderLeft:0}} disabled={node.cjg.tipo == "u" ? true : false} value={node['cjg']['dcasa']}
onBlur={()=>this.updateConj(node.cjg)}
placeholder="Data do casamento:" onChange={(e)=>{
node['cjg']['dcasa'] = e.target.value   
this.setState({ tree: this.state.tree });      
      }}/>




      
</div>
</> : <div>
<div className='pais'>
<div className='mt-2'>
<input type='text' value={node.cjg.label+' '+node.cjg.sobrenome} disabled/>
<input type='text' value={node.label+' '+node.sobrenome} disabled/>
</div>

</div>
<div className='mt-2' style={{
  display:'flex',
  justifyContent:'flex-end'
}}>

<input type='text' style={{width:'50%'}} 

onBlur={()=>this.updateConj(node.cjg)}

value={node['cjg']['dcasada']}  onChange={(e)=>{
                   node['cjg']['dcasada'] = e.target.value;
                   this.setState({ tree: this.state.tree });
                  }}

placeholder='Nome de casada'/>

</div>
</div>}            


            </div>
      </div>

      </div>
      
      {node.cjg.tipo == "u" && <>
      

  <select id="descendantRelation" onBlur={()=>this.updateMember(node)} value={node.status_c || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;
  this.updateNode(node, 'status_c', e.target.value)
 }} >              <option value="s">status civil</option>
 <option value="solteiro">Solteiro (a)</option>
 <option value="viuvo">Viuvo (a)</option>
 <option value="divorciado">Divorciado (a)</option>

               </select>
      
      
      </>}

</>}












<div className='mt-3'>

<label>Observações </label> <input type='checkbox' checked={node.obs != null ? true : false}
onChange={(e)=>{
  if(e.target.checked){
    node['obs'] = '';
    this.setState({ tree: this.state.tree });
  }else{

   delete node['obs']
   this.updateNode(node, 'obs',null)
   this.updateMember(node)
   this.setState({ tree: this.state.tree });
  }


}}
/>

<div className='mt-3'>
  {node.obs != null && <>
  <textarea className='form-control' value={node.obs} onBlur={()=>this.updateMember(node)} onChange={(e)=>{
this.updateNode(node, 'obs', e.target.value)
}}  placeholder='Digite:'/>
  </>}
</div>
</div>

<div className='mt-3'>
  
<label>Retificação </label> <input type='checkbox' checked={node.rtc != null ? true : false}
onChange={(e)=>{
  if(e.target.checked){
    node['rtc'] = '';
    this.setState({ tree: this.state.tree });
  }else{

   delete node['rtc']
   this.updateNode(node, 'rtc',null)
   this.updateMember(node)
   this.setState({ tree: this.state.tree });
  }


}}
/>

<div className='mt-3'>
  {node.rtc != null && <>
  <textarea className='form-control' value={node.rtc} onBlur={()=>this.updateMember(node)} onChange={(e)=>{
this.updateNode(node, 'rtc', e.target.value)
}}  placeholder='Digite:'/>
  </>}
</div>
</div>






{(!node.children || node.children.length === 0) && <div className='mt-3'>
  <hr/>
  <div className='d-flex justify-content-center mt-3'>
 
 <button className="btn btn-primary" onClick={(e) =>{
   


 
e.target.classList.add('load')

   fetch(`${apiUrl}/adicionar-no/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({
    id_pai:node.id,
    ref:window.location.pathname.split("/")[2]
    }),
  })
    .then(response => {
     
      e.target.classList.remove('load')
     
  
  if(response.status == 200){
  response.json().then(resp=>{
  
  
  
    this.addNode(node.id,{id:resp.id,nmr:resp.nmr,req:resp.req})
    toast.success("Descedente adicionado!")
  
  })
  }
  
  }).catch(err=>{
    e.target.classList.remove('load')
  toast.error("Falha de comunicação com servidor!")
  })
  




 }}><FontAwesomeIcon icon={faPlus}/> Adicionar descedente</button>

</div>
</div>



}


              
        <div className='mt-3'>
       
        </div> 

             
      </div>
     
    
      <div className='card-footer d-flex align-items-center justify-content-between'>








      <button class="btn btn-primary" onClick={()=>{
        this.props.navigateTo("/")
      }}>
      
        <FontAwesomeIcon icon={faSave}/> Finalizar
      </button>
      </div>

        
        </div>
      
        {node.children && node.children.length > 0 && (
         <> {node.children.map((child) => this.renderTree(child))}</>
           
       
        )}
        
 
       </>

    );
  };

  // Função para adicionar um novo filho ao nó clicado
 




  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      pessoa: {
        ...prevState.pessoa,
        [id]: value
      }
    }));
  }


  handleFileChange = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };


 TreeNode = ({ node, x, y ,cjg}) => {
   if(cjg){
    return (
      <div
        style={{
          position: 'absolute',
          top: y,
          left: x,
          width: '110px',
          height: '160px',
          backgroundColor:'#fff',
          border: `2px solid ${node.code == this.state.selected ? 'blue': '#ccc'}`,
          textAlign: 'center',
          padding: '10px',
          borderRadius:'10px',
          boxSizing: 'border-box',
          filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
        }} onClick={()=>{
          console.log(node)
        this.setState({selected:node.code})
        }}
      >

        <div class="ft-user">
         
        {node.genero == null ? '' : node.genero === "f" ? <>
        <img src={female} className='user'/>
        </>: <>
        <img src={male} className='user'/>
        </>}
        
        
         <div className='ncdd'>
         {this.Bandeira(node.ncdd)}
         </div>
        </div>
        


        <div className='mt-2'>
<p style={{fontSize:12}}>{node.label} {node.sobrenome}</p>

   
<strong style={{fontSize:12}}>{node.req}</strong>
</div>

   

     




      </div>
    );
   }else{
    return (
      <div
        style={{
          position: 'absolute',
          top: y,
          left: x,
          width: '110px',
          height: '160px',
          backgroundColor:'#fff',
          border: `2px solid ${node.id == this.state.selected ? 'blue': '#ccc'}`,
          textAlign: 'center',
          padding: '10px',
          borderRadius:'10px',
          boxSizing: 'border-box',
          filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
        }}
        onClick={()=>{
        
          this.scrollToItem(node.id)
          this.setState({selected:node.id})
          }}
      >
        <div class="ft-user">
         
        {node.genero == null ? '' : node.genero === "f" ? <>
        <img src={female} className='user'/>
        </>: <>
        <img src={male} className='user'/>
        </>}
        
        
         <div className='ncdd'>
         {this.Bandeira(node.ncdd)}
         </div>
        </div>
        

<div className='mt-2'>

{(node.genero == "f" && node.cjg != undefined) ? <div>


{(node.cjg.dcasada != null && node.cjg.dcasada != '') ? <>{node.cjg.dcasada}</> :  <p style={{fontSize:12}}>{node.label} {node.sobrenome}</p>}


</div>:<div>
  <p style={{fontSize:12}}>{node.label} {node.sobrenome}</p>
</div>}







   
<strong style={{fontSize:12}}>{node.req}</strong>
</div>

      
        




      </div>
    );
   }


  };
  
 Tree = ({ nodes, parent, x, y, spacing, cjg,children }) => {
    const totalWidth = (nodes.length - 1) * spacing;
    const startX = x - totalWidth / 2;
  
    return (
      <>
        {nodes.map((node, index) => {
          const newX = startX + index * spacing;
          const newY = y + 200;
  
          return (
            <React.Fragment key={node.id}>
              {/* Linha que liga o nó atual ao nó pai */}
             
              {parent && <>
              
                {node.cjg ? <>
                
                  <div
                      style={{
                        position: 'absolute',
                        top: y+168,
                        left: x+130,
                        width: `80px`,
                        height: `114px`,
                        borderLeft: '2px solid #9ea1a1',
                        
                      }}
                    />
                
                </>:<>
              
                  <div
                      style={{
                        position: 'absolute',
                        top: y+168,
                        left: x+50,
                        width: `80px`,
                        height: `32px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
                
                </>}
           
              
              </>}
  
  
  
            


          
              <this.TreeNode node={node} cjg={cjg} x={newX} y={newY} />
        

     
         


             

              {/* Renderizar filhos do nó atual */}
           
              {node.cjg && (
              <>
                <this.Tree nodes={[node.cjg]} children={node.children}  cjg={true} x={newX + 150} y={newY-200} spacing={spacing / 2} />
               <div
                      style={{
                        position: 'absolute',
                        top: y+280,
                        left: x+110,
                        width: `40px`,
                        height: `1px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
          
      
           {node.children != undefined && node.children.length > 0 && <>
            
            
       


            

            
            <div
                      style={{
                        position: 'absolute',
                        top: y+280,
                        left: x+130,
                        width: `1px`,
                        height: `110px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
                        <div
                      style={{
                        position: 'absolute',
                        top: y+388,
                        left: x-20,
                        width: `150px`,
                        height: `1px`,
                        borderLeft: '2px solid #9ea1a1',
                        borderTop: '2px solid #9ea1a1',
                      }}
                    />
           </>}
                     
              </>
              )}
  
              {node.children && (<>
                <this.Tree nodes={node.children} parent={node} x={newX - 150} y={newY+20} cjg={false} spacing={spacing / 2} />
            {node.cjg == undefined && <>
         
           {node.children != undefined && node.children.length > 0 && <>
            <div
                      style={{
                        position: 'absolute',
                        top: y+360,
                        left: x-20,
                        width: `80px`,
                        height: `30px`,
                        borderRight: '2px solid #9ea1a1',
                        borderBottom: '2px solid #9ea1a1',
                      }}
                    >


                  
                    </div>
           </>}
                     
                   
            
            </>}
             </>
              )}
  
            </React.Fragment>
          );
        })}
      </>
    );
  };
  
  handleWheel = (e) => {

      e.preventDefault();
      e.stopPropagation(); // Impede a propagação para outros elementos da página
  
      const scaleChange = e.deltaY < 0 ? 0.1 : -0.1; // Zoom in com scroll para cima, zoom out para baixo
      this.setState((prevState) => ({
        scale: Math.min(Math.max(prevState.scale + scaleChange, 0.5), 3), // Limita o zoom entre 0.5x e 3x
      }));
    
  };


updateConj(dados){

  fetch(`${apiUrl}/atualizar-conjugue/${dados.code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify(dados),
  })
    .then(response => {
     
      


if(response.status == 200){
toast.success("Salvo!")
}

}).catch(err=>{

  toast.error("Falha de comunicação com servidor!")
})

}


updateMember(node){


  fetch(`${apiUrl}/atualizar-arvore/${node.id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify(node),
  })
    .then(response => {
     
      
    

if(response.status == 200){
toast.success("Salvo!")
}

}).catch(err=>{

  toast.error("Falha de comunicação com servidor!")
})




}



  render() {


    if(this.state.loader){
      return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <div className='loader'>
        <div class="spinner"></div>
        </div>
        </div>)
    }

    if(this.state.error){
      return(<>
      error
      </>)
    }



    var pessoa = this.state.pessoa;
    const { tree,novo,x,y,scale } = this.state;

    
    const isChecked = this.state.tree.length > 0 && this.state.tree[0].cjg ? true : false; // Verifica se existe `tree[0]` e `cjg`

    const isFlc = this.state.tree.length > 0 && this.state.tree[0].flc ? true : false; // Verifica se existe `tree[0]` e `cjg`


    return (<>
 
    
        <div class="container-fluid">
    <div class="row area-app">

      <div class="col-12 col-md-6 full-height   d-flex justify-content-center" style={{background:"#f5f5f5"}}>
     
     
  <div class="container mt-4 mb-4">

    <div class="card mb-5">
      <div class="card-header d-flex align-items-center justify-content-between">
       <span> Família {tree[0].sobrenome}</span>
       <div>
        <button className='btn btn-primary' style={{display:'none'}}>
          <FontAwesomeIcon icon={faDownload}/> .pdf
        </button>
       </div>
      </div>

      <div class="card-body add-usr"  id="listar-avr">









{this.state.tree.length > 0 ? <>
<>

<div>
  
<select  onBlur={()=>this.updateMember(this.state.tree[0])}  value={this.state.tree[0].assr || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;

  const updatedTree = [...this.state.tree];
  updatedTree[0].assr = e.target.value;
  this.setState({ tree: updatedTree });  

 }} style={{width:'100%'}} className='mb-3'>


    <option value="s">Selecione o assessor</option>
    {this.state.tree[1].map((item,index)=><>
    <option value={item.user}>{item.user}</option>
    </>)}
  </select>
</div>



<div className='mb-3'>


<div class="card" style={{display:'nones'}}>
      <div class="card-header">
     <strong>1 - Primeiro Ancestral</strong>
      </div>
      <div class="card-body">







      <div class="form-group mb-3" style={{display:'nones'}}>





        
    
        </div>





<div className='mb-3'>




</div>



<input type="text"  

onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].label} placeholder="Nome:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].label = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>



<input type="text"  
style={{borderLeft:0,borderRight:0}}
onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].sobrenome} placeholder="Sobrenome:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].sobrenome = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>





<select  onBlur={()=>this.updateMember(this.state.tree[0])} 

disabled

value={this.state.tree[0].genero || 's'} onChange={(e)=>{
  if(e.target.value == "s") return;


  const updatedTree = [...this.state.tree];
  updatedTree[0].genero = e.target.value;
  this.setState({ tree: updatedTree });  



 }}>
                   <option value="s">Genero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>

   







<div className='mt-3 mb-3'>
           
           <input type="text"  onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].lnac} placeholder="Local nascimento:" onChange={(e)=>{
             const updatedTree = [...this.state.tree];
             updatedTree[0].lnac = e.target.value;
             this.setState({ tree: updatedTree });    
           }}/>
           
           <input type="date" 
           style={{borderLeft:0,borderRight:0}}
           onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].dnac} placeholder="Local nascimento:" onChange={(e)=>{
             const updatedTree = [...this.state.tree];
             updatedTree[0].dnac = e.target.value;
             this.setState({ tree: updatedTree });    
           }}/>
           
       
           <select id="descendantRelation"
           
           disabled
           
           onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].ncdd || 's'} onChange={(e)=>{
            
            if(e.target.value == "s") return;
             const updatedTree = [...this.state.tree];
  updatedTree[0].ncdd = e.target.value;
  this.setState({ tree: updatedTree });  
            }} >              <option value="s">País:</option>
                              <option value="Brasil">Brasil</option>
                              <option value="Itália">Itália</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Polonia">Polonia</option>
                              <option value="Espanha">Espanha</option>
           
                          </select>
           
                          </div>
                        

     
        
<div style={{display:'flex',flexDirection:'column'}} className='mt-3 mb-3'>
  <label>Pais:</label>

<div className='pais'>

<div>
<input type="text"

onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].pai} placeholder="Nome do pai:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].pai = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>

<input type="text" 

onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].spai} placeholder="Sobrenome do pai:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].spai = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>
</div>

</div>

<div className='pais'>

<div>
<input type="text"  style={{marginTop:10}} onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].mae} placeholder="Nome da mãe:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].mae = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>

<input type="text"  style={{marginTop:10}} 
onBlur={()=>this.updateMember(this.state.tree[0])}
 value={this.state.tree[0].smae} placeholder="Sobrenome da mãe:" onChange={(e)=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0].smae = e.target.value;
  this.setState({ tree: updatedTree });    
}}/>
</div>

</div>


</div>








<div>

</div>

<div className='mt-2 mb-2' style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <label>Falecido (a): </label> 
                
                <input 
            type="checkbox" 
           style={{marginLeft:5}}
            checked={isFlc}
            onBlur={()=>this.updateMember(this.state.tree[0])}
            onChange={(event)=>{
              const isChecked = event.target.checked;
              const updatedTree = [...this.state.tree];
          
              if (isChecked) {
                // Se marcado, adiciona `cjg: {}`
                updatedTree[0] = { ...updatedTree[0], flc: {} };
              } else {
                // Se desmarcado, remove `cjg`
                const { flc, ...rest } = updatedTree[0]; // Remove a chave `cjg`
                updatedTree[0] = rest;
              }
          
              this.setState({ tree: updatedTree });
            }} 
          />
               </div>

               {this.state.tree[0].flc != undefined && <div className='mt-2 mb-2'>
               
               
               
               
               
                <input type="text" id="spouseName" value={this.state.tree[0].flc.local}
                onBlur={()=>this.updateMember(this.state.tree[0])}
                placeholder="Local defalecimento" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].flc.local = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>    
               
               

  <input type="date" id="spouseName" style={{borderLeft:0}} value={this.state.tree[0].flc.data}
   onBlur={()=>this.updateMember(this.state.tree[0])}
  placeholder="Data do falecimento" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].flc.data = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>  
<select id="descendantRelation" 
style={{borderLeft:0}}
disabled={this.state.tree[0].cjg.tipo == "u" ? true : false} onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].bflc || 's'} onChange={(e)=>{
             if(e.target.value == "s") return;
        


           const updatedTree = [...this.state.tree];
           updatedTree[0].bflc = e.target.value;
           this.setState({ tree: updatedTree });  




            }} >              <option value="s">País:</option>
                              <option value="Brasil">Brasil</option>
                              <option value="Itália">Itália</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Polonia">Polonia</option>
                              <option value="Espanha">Espanha</option>
           
           </select>

               
               </div>}

 {this.state.tree[0].cjg == undefined ? <>

<button className='btn btn-primary mt-2' onClick={(e)=>{






e.target.classList.add('load')
var g = this.state.tree[0].genero == 'm' ? 'f' : 'm'
fetch(`${apiUrl}/adicionar-conjugue/${tree[0].id}`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
  body: JSON.stringify({tipo:'c',g}),
})
  .then(response => {
    e.target.classList.remove('load')
    if(response.status == 200){

response.json().then(data=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0] = { ...updatedTree[0], cjg: {code:data.code,tipo:'c',genero:g} };
  this.setState({ tree: updatedTree });
  toast.success("Casamento adicionado")
})




    }


  }).catch(err=>{
    toast.error("Falha de comunicação com o srvidor!")
  })






}}>
Adicionar casamento
</button>





<button className='btn btn-primary mt-2' style={{marginLeft:10}} onClick={(e)=>{
e.target.classList.add('load')
var g = this.state.tree[0].genero == 'm' ? 'f' : 'm'
fetch(`${apiUrl}/adicionar-conjugue/${tree[0].id}`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
  body: JSON.stringify({tipo:'u',g}),
})
  .then(response => {
    e.target.classList.remove('load')
    if(response.status == 200){

response.json().then(data=>{
  const updatedTree = [...this.state.tree];
  updatedTree[0] = { ...updatedTree[0], cjg: {code:data.code,tipo:'u',genero:g} };
  this.setState({ tree: updatedTree });
  toast.success("União adicionada")
})




    }


  }).catch(err=>{
    toast.error("Falha de comunicação com o srvidor!")
  })






}}>
Adicionar união
</button>


<hr className='mt-3'/>

</> : <>
<br/>













<div class="card mb-2 mt-3">
        
      <div class="card-header d-flex align-items-center justify-content-between">
           <span>
            {this.state.tree[0].cjg.tipo == "c" ? 'Dados do cônjuge' : 'Dados do companheiro (a)'}
           </span>
           <button className='btn btn-danger' onClick={(e)=>{
  





  e.target.classList.add('load')

  fetch(`${apiUrl}/remover-conjugue/${tree[0].id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({}),
  })
    .then(response => {
      e.target.classList.remove('load')

      if(response.status == 200){
        const updatedTree = [...this.state.tree];
        const { cjg, ...rest } = updatedTree[0]; // Remove a chave `cjg`
        updatedTree[0] = rest;
        this.setState({ tree: updatedTree });
        toast.success("Cônjugue removido!")
      }
  
  
    }).catch(err=>{
      toast.error("Falha de comunicação com o srvidor!")
    })
  








  
           }}>
            <FontAwesomeIcon icon={faTrash}/>
           </button>
        
      </div>
        
    
    {/*aqui*/}
    
    
    
      <div class="card-body">

    


{this.state.tree[0].cjg.tipo == "c" ? <div>
 <div>

   {this.state.tree[0].genero == "m" ? <>
   
   



   
   
   
   
   </> : <>
    
    <div style={{display:'flex'}}>
 

<input type="text" id="spouseName"

 style={{width:'100%'}}
placeholder="Nome do esposo:" onChange={(e)=>{
                
}}/>


<input type="text" id="spouseName"
  value={this.state.tree[0].label+''+ this.state.tree[0].sobrenome}
 style={{width:'100%'}}
 placeholder="Nome do solteira:" disabled/>












   </div>
   <div className='mt-2 mb-2'>
<input type="text" id="spouseName"

 style={{width:'100%'}}
 placeholder="Nome de casada:" onChange={(e)=>{
              
}}/>
</div>
   
   
   </>}







</div>
</div> : <>

<input type="text" id="spouseName" 
onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
style={{width:'100%'}}
value={this.state.tree[0].cjg.label} placeholder="Nome:" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.label = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>
</>}


      


<div className='pais'>
<div>
<input type="text" id="spouseName" value={this.state.tree[0].cjg.label} 
      onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
      placeholder="Nome do cônjugue" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.label = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>

<input type="text" id="spouseName" value={this.state.tree[0].cjg.sobrenome} 
      onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
      placeholder="Sobrenome do cônjugue" onChange={(e)=>{

                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.sobrenome = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>
</div>
</div>




     








            <div style={{marginTop:10}}>
              
            <select id="spouseGender" 
             onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
        
            value={this.state.tree[0].cjg.genero || 's'} onChange={(e)=>{
              if(e.target.value == "s") return;
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.genero = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}>
                <option value="s">Genero:</option>
                <option value="m">Masculino</option>
                <option value="f">Feminino</option>
            </select>


            <input type="text" id="spouseName" style={{borderLeft:0}}
            onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
            value={this.state.tree[0].cjg.lnac} placeholder="Local de nascimento:" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.lnac = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>



 


               
 <select id="descendantRelation"
    style={{borderLeft:0}}
  onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
 value={this.state.tree[0].cjg.ncdd || 's'} onChange={(e)=>{
if(e.target.value == "s") return;
const { tree, inputValue } = this.state;

// Clonando o array para não mutar o estado diretamente
const updatedTree = [...tree];

// Adicionando o novo item ao objeto cjg do primeiro índice
updatedTree[0].cjg.ncdd = e.target.value;

// Atualizando o estado
this.setState({ tree: updatedTree, inputValue: '' });
 }} >              <option value="s">País:</option>
                   <option value="Brasil">Brasil</option>
                   <option value="Itália">Itália</option>
                   <option value="Portugal">Portugal</option>
                   <option value="Polonia">Polonia</option>
                   <option value="Espanha">Espanha</option>

               </select>


               <input type="date" id="spouseName" 
 style={{borderLeft:0}}
 onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
value={this.state.tree[0].cjg.dnac} placeholder="Local de nascimento:" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.dnac = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
                }}/>  



            </div>




<div className='mt-3 mb-3' class="pais" style={{marginTop:10}}>
  <label>Pais:</label>
<div>
<input type="text" id="spouseName" value={this.state.tree[0].cjg.pai} 
      onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
      placeholder="Nome do pai" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.pai = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>
<input type="text" id="spouseName" value={this.state.tree[0].cjg.spai} 
      onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
      placeholder="Sobrenome do pai" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.spai = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>
</div>

<div className='mt-2'>
<input type="text" id="spouseName" value={this.state.tree[0].cjg.mae} 
      onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
      placeholder="Nome da mãe" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.mae = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>

<input type="text" id="spouseName" value={this.state.tree[0].cjg.smae} 
      onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
      placeholder="Sobrenome da mãe" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.smae = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}}/>
</div>

<br/>







<strong>Casamento</strong>





<div className='pais'>

<input
  type="text"
  value={
    this.state.tree && this.state.tree[0]
      ? `${this.state.tree[0].label || ''} ${this.state.tree[0].sobrenome || ''}`
      : ''
  }
  disabled
/>

<input
  type="text"
  value={
    this.state.tree && this.state.tree[0] && this.state.tree[0].cjg
      ? `${this.state.tree[0].cjg.label || ''} ${this.state.tree[0].cjg.sobrenome || ''}`
      : ''
  }
  disabled
/>

</div>


<div className='mt-2'>
<input type="text" id="spouseName" value={this.state.tree[0].cjg.lcasa} 
onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
placeholder="Local do casamento:"

onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.lcasa = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
}} disabled={this.state.tree[0].cjg.tipo == "u" ? true : false}/>





<select id="descendantRelation" 
style={{borderLeft:0}}
disabled={this.state.tree[0].cjg.tipo == "u" ? true : false} onBlur={()=>this.updateMember(this.state.tree[0])} value={this.state.tree[0].bcasa || 's'} onChange={(e)=>{
             if(e.target.value == "s") return;
             const updatedTree = [...this.state.tree];
             updatedTree[0].bcasa = e.target.value;
             this.setState({ tree: updatedTree });  
            }} >              <option value="s">País:</option>
                              <option value="Brasil">Brasil</option>
                              <option value="Itália">Itália</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Polonia">Polonia</option>
                              <option value="Espanha">Espanha</option>
           
           </select>


           <input type="date" id="spouseName" style={{borderLeft:0}}  value={this.state.tree[0].cjg.dcasa}
onBlur={(e)=>{
var i = (e.target.value)
if(i == ""){
  toast.warn("Informe a data do casamento")
  return
}

if(this.state.tree[0].dnac == null){
  toast.warn("Iforme a data de nascimento do requerente")
  return;
}

if(new Date(e.target.value) <= new Date(this.state.tree[0].dnac)){
  e.target.value = null;
  toast.warn("A data do casamento tem que ser posterior a data de nascimento")
  return;
}

this.updateConj(this.state.tree[0].cjg)

}}
placeholder="Data do casamento:" onChange={(e)=>{
                  const { tree, inputValue } = this.state;

                  // Clonando o array para não mutar o estado diretamente
                  const updatedTree = [...tree];
                  
                  // Adicionando o novo item ao objeto cjg do primeiro índice
                  updatedTree[0].cjg.dcasa = e.target.value;
              
                  // Atualizando o estado
                  this.setState({ tree: updatedTree, inputValue: '' }); // Reseta o input após adicionar
              
      }} disabled={this.state.tree[0].cjg.tipo == "u" ? true : false}/>

</div>




</div>

      </div>



      
      </div>


{
  this.state.tree[0].cjg.tipo == "u" && <div className='mt-3'>
  <select onBlur={()=>this.updateConj(this.state.tree[0].cjg)}
 onChange={(e)=>{
if(e.target.value == "s") return;
const { tree, inputValue } = this.state;

// Clonando o array para não mutar o estado diretamente
const updatedTree = [...tree];

// Adicionando o novo item ao objeto cjg do primeiro índice
updatedTree[0].cjg.status_c = e.target.value;

// Atualizando o estado
this.setState({ tree: updatedTree, inputValue: '' });
 }}  value={this.state.tree[0].cjg.status_c || 's'}>
    <option value="s">status civil</option>
    <option value="solteiro">Solteiro (a)</option>
    <option value="viuvo">Viuvo (a)</option>
    <option value="divorciado">Divorciado (a)</option>
  </select>
  </div>
}




<div className='mt-3'>
<label>Observações </label> <input type='checkbox' checked={this.state.tree[0].obs != null ? true : false} 
 onChange={(event)=>{
  const isChecked = event.target.checked;
  const updatedTree = [...this.state.tree];

  if (isChecked) {
    // Se marcado, adiciona `cjg: {}`
    updatedTree[0] = { ...updatedTree[0], obs: '' };
  } else {
    // Se desmarcado, remove `cjg`
    const { obs, ...rest } = updatedTree[0]; 
    updatedTree[0] = rest;
    this.updateMember(updatedTree[0])

  }

  
  this.setState({ tree: updatedTree });
 
}} 
/>

<div className='mt-3'>
  {this.state.tree[0].obs != null && <>
  <textarea className='form-control' value={this.state.tree[0].obs} onBlur={()=>this.updateMember(this.state.tree[0])} onChange={(e)=>{
     const { tree, inputValue } = this.state;

     // Clonando o array para não mutar o estado diretamente
     const updatedTree = [...tree];
     
     // Adicionando o novo item ao objeto cjg do primeiro índice
     updatedTree[0].obs = e.target.value;
 
     // Atualizando o estado
     this.setState({ tree: updatedTree });
  }}  placeholder='Digite:'/>
  </>}
</div>



</div>


<div className='mt-3'>
<label>Retificação </label> <input type='checkbox' checked={this.state.tree[0].rtc != null ? true : false} 
 onChange={(event)=>{
  const isChecked = event.target.checked;
  const updatedTree = [...this.state.tree];

  if (isChecked) {
    // Se marcado, adiciona `cjg: {}`
    updatedTree[0] = { ...updatedTree[0], rtc: '' };
  } else {
    // Se desmarcado, remove `cjg`
    const { rtc, ...rest } = updatedTree[0]; 
    updatedTree[0] = rest;
    this.updateMember(updatedTree[0])

  }

  
  this.setState({ tree: updatedTree });
 
}} 
/>

<div className='mt-3'>
  {this.state.tree[0].rtc != null && <>
  <textarea className='form-control' value={this.state.tree[0].rtc} onBlur={()=>this.updateMember(this.state.tree[0])} onChange={(e)=>{
     const { tree, inputValue } = this.state;

     // Clonando o array para não mutar o estado diretamente
     const updatedTree = [...tree];
     
     // Adicionando o novo item ao objeto cjg do primeiro índice
     updatedTree[0].rtc = e.target.value;
 
     // Atualizando o estado
     this.setState({ tree: updatedTree });
  }}  placeholder='Digite:'/>
  </>}
</div>



</div>

</>}

{tree[0].children.length == 0  && <>
  <div className='d-flex justify-content-center mt-3'>
<button className='btn btn-primary' onClick={(e)=>{





e.target.classList.add('load')

fetch(`${apiUrl}/adicionar-no/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
  body: JSON.stringify({
  id_pai:tree[0].id,
  ref:window.location.pathname.split("/")[2]
  }),
})
  .then(response => {
   
    e.target.classList.remove('load')
   

if(response.status == 200){
response.json().then(resp=>{


  const newTree = [...this.state.tree];
  newTree[0] = {
    ...newTree[0],
    children: [...(newTree[0].children || []), {
      id:resp.id,
      nmr:resp.nmr,
      ncdd:'Brasil',
      req:resp.req
    }],
  };

  this.setState({ tree: newTree});
  toast.success("Descedente adicionado!")

})
}

}).catch(err=>{
  e.target.classList.remove('load')
toast.error("Falha de comunicação com servidor!")
})











}}>
<FontAwesomeIcon icon={faPlus}/>  Adicionar descedente
</button>
</div>
</>}  
     
          



     
      </div>
      <div className='card-header d-flex align-items-center justify-content-between'>
      

      

    






      <button class="btn btn-primary" onClick={()=>{
        this.props.navigateTo("/")
      }}>
        
        <FontAwesomeIcon icon={faSave}/> Finalizar
      </button>
      </div> </div>




             
</div>

</>


{this.state.tree[0].children != undefined && tree[0].children.map((node) => this.renderTree(node))}






</>:<div className='d-flex justify-content-center align-items-center'>

<button className='btn btn-primary' onClick={()=>{
  
  
  fetch(`${apiUrl}/criar-arvore/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({
 
    }),
  })
    .then(response => {
     
      
     

if(response.status == 200){

}

})




  
  
  
  this.setState((prevState) => ({
    tree: [...prevState.tree,{id:this.GerToken(4),label:'Nome do Ancestral',children:[]}]
  }));
}}>
  Criar
</button>


</div>}





      </div>
    </div>
    <div style={{height:100,width:'100%'}}/>
  </div>



   
      </div>

      <div class="col-12 col-md-6"    onWheel={this.handleWheel}      onMouseDown={this.handleMouseDown}  style={{background:'#dddfdf'}}>
     
      <div className='area-avr' style={{ position: 'relative', width: '100%' }}>
      
<div  style={{
            position: 'absolute',
            left: x,
            top: y,
            transform: `scale(${scale})`, // Aplica o zoom
            cursor: 'grab',
          }}>




      <this.Tree  nodes={[this.state.tree[0]]} parent={null} x={550} y={-100} spacing={200} />
    
    </div>
    </div>
      </div>
    </div>
  </div>



 







  <StaticDialog
        isOpen={this.state.trash != undefined}
        title="Atenção"
        onAfterClose={(result) => {
 this.setState({trash:undefined})
          // do something with dialog result
        }}
    >

<div style={{margin:10}}>
<p style={{textAlign:'center'}}>Ao remover este parente, todos os parentes abaixo dele também serão removidos. 
Esta ação não poderá ser desfeita. Deseja continuar?</p>
</div>


<div className='modal-btn'>
<button className='btn btn-danger' onClick={(e)=>{

e.target.classList.add('load')
fetch(`${apiUrl}/remover-parente/`+this.state.trash, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
})
  .then(response => {
    e.target.classList.remove('load')
  if(response.status == 200){
    this.removeNode(this.state.trash)
    this.setState({trash:undefined})
    toast.success("Descedente removido!")
  }
  }).catch(err=>{
e.target.classList.remove('load')
toast.error("Falha de cominicação com o servidor!")
  })










}}>
<FontAwesomeIcon icon={faTrash}/> Sim
</button>
</div>

    </StaticDialog>





<StaticDialog
        isOpen={this.state.addno != undefined}
        title="Adicionar descendente"
        onAfterClose={(result) => {
 this.setState({addno:undefined})
          // do something with dialog result
        }}
    >

<div style={{
  display:'table',
  margin:'auto',
  marginTop:15

}}>



<input type="text"  value={novo.label} placeholder="Nome do Descendente" onChange={(e)=>{
 this.setState((prevState) => ({
  novo: {
    ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
    label:e.target.value
  }
}));
}}/>

 <select  onChange={(e)=>{
  if(e.target.value == "s") return;
  this.setState((prevState) => ({
    novo: {
      ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
      genero:e.target.value
    }
  }));
 }}>
                   <option value="s">Gênero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>
               
<select id="descendantRelation"
style={{display:'none'}}
onChange={(e)=>{
    if(e.target.value == "s") return;
 this.setState((prevState) => ({
  novo: {
    ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
    req:e.target.value
  }
}));
 }} >              <option value="s">Denominação</option>
  <option value="Filho">Filho(a)</option>
  <option value="Neto">Neto(a)</option>
  <option value="Bisneto">Bisneto(a)</option>
  <option value="Trineto">Trineto(a)</option>
  <option value="Tetraneto(a) (Requerente)">Tetraneto(a) (Requerente)</option>
  <option value="Tetraneto(a) (Menores)">Tetraneto(a) (Menores)</option>
               </select>
              
              


</div>

<div className='m-btn'>

<button className='btn btn-primary' onClick={()=>{

var data = this.state.novo;


fetch(`${apiUrl}/adicionar-no/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
  body: JSON.stringify({
  id_pai:this.state.addno,
  label:data.label,
  re:data.req,
  genero:data.genero,
  ref:window.location.pathname.split("/")[2]
  }),
})
  .then(response => {
   
    
   

if(response.status == 200){
response.json().then(resp=>{



  this.addNode(this.state.addno,{id:resp.id,label:data.label,
    req:data.req,
    genero:data.genero,
    req:resp.req,
    nmr:resp.nmr
  })
  this.setState({addno:undefined})
  

  this.setState({novo:{},  addM:false});
  toast.success("Salvo!")

})
}

}).catch(err=>{
toast.error("Falha de comunicação com servidor!")
})






}}>

<FontAwesomeIcon icon={faSave}/> Salvar

</button>

</div>




    </StaticDialog>



  <StaticDialog
        isOpen={this.state.addM}
        title="Adicionar descendente"
        onAfterClose={(result) => {
 this.setState({addM:false})
          // do something with dialog result
        }}
    >

<div style={{
  display:'table',
  margin:'auto',
  marginTop:15

}}>



<input type="text"  value={novo.label} placeholder="Nome do Descendente" onChange={(e)=>{
 this.setState((prevState) => ({
  novo: {
    ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
    label:e.target.value
  }
}));
}}/>

 <select  onChange={(e)=>{
  if(e.target.value == "s") return;
  this.setState((prevState) => ({
    novo: {
      ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
      genero:e.target.value
    }
  }));
 }}>
                   <option value="s">Gênero:</option>
                   <option value="m">Masculino</option>
                   <option value="f">Feminino</option>
</select>
               
<select id="descendantRelation"
style={{display:'none'}}
onChange={(e)=>{
    if(e.target.value == "s") return;
 this.setState((prevState) => ({
  novo: {
    ...prevState.novo,  // mantém o conteúdo atual do state 'novo'
    req:e.target.value
  }
}));
 }} >              <option value="s">Denominação</option>
  <option value="Filho">Filho(a)</option>
  <option value="Neto">Neto(a)</option>
  <option value="Bisneto">Bisneto(a)</option>
  <option value="Trineto">Trineto(a)</option>
  <option value="Tetraneto(a) (Requerente)">Tetraneto(a) (Requerente)</option>
  <option value="Tetraneto(a) (Menores)">Tetraneto(a) (Menores)</option>
               </select>
              
              


</div>

<div className='m-btn'>

<button className='btn btn-primary' onClick={()=>{
var data = this.state.novo;
fetch(`${apiUrl}/adicionar-no/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
  body: JSON.stringify({
  id_pai:tree[0].id,
  label:data.label,
  re:data.req,
  genero:data.genero,
  ref:window.location.pathname.split("/")[2]
  }),
})
  .then(response => {
   
    
   

if(response.status == 200){
response.json().then(resp=>{


  var data = this.state.novo;
  const newTree = [...this.state.tree];
  
  // Adiciona um filho ao índice 0
  newTree[0] = {
    ...newTree[0],
    children: [...(newTree[0].children || []), {
      id:resp.id,
      label:data.label,
      req:data.req,
      genero:data.genero,
      nmr:resp.nmr
    }],
  
  };
  
  // Atualiza o estado com a nova árvore
  this.setState({ tree: newTree,novo:{},  addM:false});
  toast.success("Salvo!")

})
}

}).catch(err=>{
toast.error("Falha de comunicação com servidor!")
})




}}>
<FontAwesomeIcon icon={faSave} color='#fff'/>
Salvar
</button>

</div>

</StaticDialog>



        </>
    );
  }
}

export default EdtArvore;