import React, { Component } from 'react';
import logo from "../../../src/images/logo-c.png"
import "./p.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';
import male from "../../images/male.png"
import female from "../../images/female.png"
import br from "../../images/br.png"
import pt from "../../images/pt.png"
import es from "../../images/es.png"
import it from "../../images/it.png"
import po from "../../images/po.png"
import casa from "../../images/casa.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
class PreView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    loader:true,

    };
  }



  componentDidMount(){
    document.title = "Editar árvore"
    const rootElement = document.getElementById('root');
    if(rootElement){
      rootElement.classList.remove("fixa")
    }
    
    
      
    fetch(`${apiUrl}/listar-arvore/${window.location.pathname.split("/")[2]}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // outras headers, se necessário
      }
    })
      .then(response => {
        if(response.status == 404){
          this.setState({error:true,loader:false})
          return;
        }
    
    
    
    if(response.status == 200){
      response.json().then(data=>{
        this.setState({loader:false,tree:data})
        })
    }
      }).catch(err=>{
        toast.error("Falha de comunicação com o servidor!")
      })
    
    
    
    
      }


      Bandeira(e){
        switch(e){
          case 'Brasil':
          return <img src={br}/>
      
          case 'Itália':
          return <img src={it}/>
      
          case 'Portugal':
          return <img src={pt}/>
      
          case 'Espanha':
          return <img src={es}/>
      
          case 'Polonia':
          return <img src={po}/>
          
      
          
      
        }
      }
      

     FormatD(dataISO) {
        return dataISO.split('-').reverse().join('/');
    }



     
      formatarData = (data) => {
        if(data == null){
            return(<>Não informado</>)
        }
        const [ano, mes, dia] = data.split('-');
        return `${dia}/${mes}/${ano}`;
      }

      TreeNode = ({ node, x, y ,cjg}) => {
        if(cjg){
         return (
           <div
             style={{
               position: 'absolute',
               top: y,
               left: x,
               width: '110px',
               height: '160px',
               backgroundColor:'#fff',
               border: '1px solid #cacccc',
               textAlign: 'center',
               padding: '10px',
               borderRadius:'10px',
               boxSizing: 'border-box',
               filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
             }}
           >
             <div class="ft-user">
              
             {node.genero === undefined ? 'Sem foto' : node.genero === "f" ? <>
             <img src={female} className='user'/>
             </>: <>
             <img src={male} className='user'/>
             </>}
             
             
              <div className='ncdd'>
              {this.Bandeira(node.ncdd)}
              </div>
             </div>
             
     
     
             <p>{node.ncasada}</p>
     
          
     
     
     
     
           </div>
         );
        }else{
         return (
           <div
             style={{
               position: 'absolute',
               top: y,
               left: x,
               width: '110px',
               height: '160px',
               backgroundColor:'#fff',
               border: '1px solid #cacccc',
               textAlign: 'center',
               padding: '10px',
               borderRadius:'10px',
               boxSizing: 'border-box',
               filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
             }}
           >
             <div class="ft-user">
              
             {node.genero === undefined ? 'Sem foto' : node.genero === "f" ? <>
             <img src={female} className='user'/>
             </>: <>
             <img src={male} className='user'/>
             </>}
             
             
              <div className='ncdd'>
              {this.Bandeira(node.ncdd)}
              </div>
             </div>
             
     
     
             <p>{node.label} {node.sobrenome}</p>
     
             <div>
               <strong style={{fontSize:14}}>{node.req ? <>{node.req}</> : <>dante causa</>}</strong>
             </div>
     
     
     
     
           </div>
         );
        }
     
     
       };
       
      Tree = ({ nodes, parent, x, y, spacing, cjg,children }) => {
         const totalWidth = (nodes.length - 1) * spacing;
         const startX = x - totalWidth / 2;
       
         return (
           <>
             {nodes.map((node, index) => {
               const newX = startX + index * spacing;
               const newY = y + 200;
       
               return (
                 <React.Fragment key={node.id}>
                   {/* Linha que liga o nó atual ao nó pai */}
                  
                   {parent && <>
                   
                     {node.cjg ? <>
                     
                       <div
                           style={{
                             position: 'absolute',
                             top: y+168,
                             left: x+130,
                             width: `80px`,
                             height: `114px`,
                             borderLeft: '2px solid #9ea1a1',
                             
                           }}
                         />
                     
                     </>:<>
                   
                       <div
                           style={{
                             position: 'absolute',
                             top: y+168,
                             left: x+50,
                             width: `80px`,
                             height: `32px`,
                             borderLeft: '2px solid #9ea1a1',
                             borderTop: '2px solid #9ea1a1',
                           }}
                         />
                     
                     </>}
                
                   
                   </>}
       
       
       
                 
     
     
               
                   <this.TreeNode node={node} cjg={cjg} x={newX} y={newY} />
             
     
          
              
     
     
                  
     
                   {/* Renderizar filhos do nó atual */}
                
                   {node.cjg && (
                   <>
                     <this.Tree nodes={[node.cjg]} children={node.children}  cjg={true} x={newX + 150} y={newY-200} spacing={spacing / 2} />
                    <div
                           style={{
                             position: 'absolute',
                             top: y+280,
                             left: x+110,
                             width: `40px`,
                             height: `1px`,
                             borderLeft: '2px solid #9ea1a1',
                             borderTop: '2px solid #9ea1a1',
                           }}
                         />
               
           
                {node.children != undefined && node.children.length > 0 && <>
                 
                 
            
     
     
                 
     
                 
                 <div
                           style={{
                             position: 'absolute',
                             top: y+280,
                             left: x+130,
                             width: `1px`,
                             height: `110px`,
                             borderLeft: '2px solid #9ea1a1',
                             borderTop: '2px solid #9ea1a1',
                           }}
                         />
                             <div
                           style={{
                             position: 'absolute',
                             top: y+388,
                             left: x-20,
                             width: `150px`,
                             height: `1px`,
                             borderLeft: '2px solid #9ea1a1',
                             borderTop: '2px solid #9ea1a1',
                           }}
                         />
                </>}
                          
                   </>
                   )}
       
                   {node.children && (<>
                     <this.Tree nodes={node.children} parent={node} x={newX - 150} y={newY+20} cjg={false} spacing={spacing / 2} />
                 {node.cjg == undefined && <>
              
                {node.children != undefined && node.children.length > 0 && <>
                 <div
                           style={{
                             position: 'absolute',
                             top: y+360,
                             left: x-20,
                             width: `80px`,
                             height: `30px`,
                             borderRight: '2px solid #9ea1a1',
                             borderBottom: '2px solid #9ea1a1',
                           }}
                         >
     
     
                       
                         </div>
                </>}
                          
                        
                 
                 </>}
                  </>
                   )}
       
                 </React.Fragment>
               );
             })}
           </>
         );
       };




       FindByReq(data, reqValue) {
        let result = [];
    
        function search(children) {
            if (!Array.isArray(children)) return; // Verifica se `children` é um array
            
            for (const item of children) {
                if (item.req === reqValue) {
                    result.push(item);
                }
                if (Array.isArray(item.children) && item.children.length > 0) {
                    search(item.children);
                }
            }
        }
    
        search(data);
        return result[0];
    }
    




RenderItem(data){

  var {eu,pais,avos} = data;
 
  return(<>
   <div className='a4-item' style={{borderBottom:'solid 1px'}}>

   <div class="area-l">


   <div style={{display:'flex',alignItems:'center',marginBottom:20}}>

{
  eu.genero == "m"  ? <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img src={female} width={25} height={25} style={{marginRight:5}}/>
}


  <span>Denominação:<strong> {eu.req} (a)</strong> {eu.nmr != null && <>
  - <strong>{eu.nmr}</strong>
  </>}</span>
 </div>

 <div>
  <label>Nome: <strong>{eu.label} {eu.sobrenome}</strong></label>
 </div>





<div className='ncm'>
  <div>
  <span>nascimento: <strong>{eu.lnac != null ? eu.lnac : '---'}</strong></span>
  {this.Bandeira(this.state.tree[0].ncdd)}</div>
  
  <div>
  Data: <strong>{eu.dnac != null ? this.formatarData(eu.dnac ): '---' }</strong>
  </div>

  {this.Bandeira(eu.ncdd)}
</div>






{eu.req == "Filho" && <>






  <div className='d-flex align-items-center '>
  <span>NOME DO PAI: <strong>{pais.label != null ? pais.label+' '+pais.sobrenome : '---' }</strong></span>
</div>

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>NOME DA MÃE: <strong>{pais.cjg.ncasada != null ? pais.cjg.ncasada : '---' }</strong></span>
</div>
</>}



{pais.pai != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{pais.pai != null ? pais.pai+' '+pais.spai : '---' }</strong></span>
</div>
</>}

{pais.pai != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ PATERNO: <strong>{pais.mae != null ? pais.mae+' '+pais.smae : '---' }</strong></span>
</div>
</>}





{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ materna: <strong>{pais.cjg.pai != null ? pais.cjg.pai+' '+pais.cjg.spai : '---' }</strong></span>
</div>
</>}

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ materna: <strong>{pais.cjg.mae != null ? pais.cjg.mae+' '+pais.smae : '---' }</strong></span>
</div>
</>}
</>}


{(eu.req == "Neto")&& <>

{pais.genero == "f" ? <>

<div className='d-flex align-items-center '>
  <span>Nome do pai: <strong>{pais.cjg.ncasada != null ? pais.cjg.ncasada : '---' }</strong></span>
</div>
<div className='d-flex align-items-center '>
  <span>Nome da mãe: <strong>{pais.label != null ? pais.label+' '+pais.sobrenome : '---' }</strong></span>
</div>

  {pais.pai != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{pais.pai != null ? pais.pai+' '+pais.spai : '---' }</strong></span>
</div>
</>}

{pais.pai != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ PATERNO: <strong>{pais.mae != null ? pais.mae+' '+pais.mae : '---' }</strong></span>
</div>
</>}






{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{pais.cjg.pai != null ? pais.cjg.pai+' '+pais.cjg.spai : '---' }</strong></span>
</div>
</>}

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ PATERNO: <strong>{pais.cjg.mae != null ? pais.cjg.mae+' '+pais.cjg.smae : '---' }</strong></span>
</div>
</>}

{avos != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ materna: <strong>{avos.label != null ? avos.label+' '+avos.sobrenome  : '---' }</strong></span>
</div>
</>}


{avos.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ materna: <strong>{avos.cjg.ncasada != null ? avos.cjg.ncasada  : '---' }</strong></span>
</div>
</>}

</> : <>
<div className='d-flex align-items-center '>
  <span>Nome do pai: <strong>{pais.cjg.ncasada != null ? pais.cjg.ncasada : '---' }</strong></span>
</div>
<div className='d-flex align-items-center '>
  <span>Nome da mãe: <strong>{pais.label != null ? pais.label+' '+pais.sobrenome : '---' }</strong></span>
</div>




{avos.label != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{avos.label != null ? avos.label+' '+avos.sobrenome : '---' }</strong></span>
</div>
</>}

{avos.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ materna: <strong>{avos.cjg.ncasada != null ? avos.cjg.ncasada : '---' }</strong></span>
</div>
</>}




{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ materna: <strong>{pais.cjg.pai != null ? pais.cjg.pai+' '+pais.cjg.spai : '---' }</strong></span>
</div>
</>}

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ materna: <strong>{pais.cjg.mae != null ? pais.cjg.mae+' '+pais.cjg.smae : '---' }</strong></span>
</div>
</>}

</>}
</>}




{/*aqui*/}

{(eu.req == "Bisneto")&& <>

{pais.genero == "f" ? <>

  {pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>NOME Do pai: <strong>{pais.cjg.ncasada != null ? pais.cjg.ncasada : '---' }</strong></span>
</div>
</>}
<div className='d-flex align-items-center '>
  <span>NOME Da mãe: <strong>{pais.label != null ? pais.label+' '+pais.sobrenome : '---' }</strong></span>
</div>

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{pais.cjg.pai != null ? pais.cjg.pai+' '+pais.cjg.spai : '---' }</strong></span>
</div>
</>}

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ PATERNO: <strong>{pais.cjg.mae != null ? pais.cjg.mae+' '+pais.cjg.smae : '---' }</strong></span>
</div>
</>}



{avos.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ materna: <strong>{avos.cjg.ncasada != null ? avos.cjg.ncasada : '---' }</strong></span>
</div>
</>}

{avos.label != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVó PATERNO: <strong>{avos.label != null ? avos.label+' '+avos.sobrenome : '---' }</strong></span>
</div>
</>}


</> : <>

<div className='d-flex align-items-center '>
  <span>NOME DO PAI: <strong>{pais.label != null ? pais.label+' '+pais.sobrenome : '---' }</strong></span>
</div>

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>NOME DA MÃE: <strong>{pais.cjg.ncasada != null ? pais.cjg.ncasada : '---' }</strong></span>
</div>
</>}


{avos.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{avos.cjg.ncasada != null ? avos.cjg.ncasada : '---' }</strong></span>
</div>
</>}

{avos.label != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVó PATERNO: <strong>{avos.label != null ? avos.label+' '+avos.sobrenome : '---' }</strong></span>
</div>
</>}







{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÔ materna: <strong>{pais.cjg.pai != null ? pais.cjg.pai+' '+pais.cjg.spai : '---' }</strong></span>
</div>
</>}

{pais.cjg != undefined && <>
<div className='d-flex align-items-center '>
  <span>AVÓ materna: <strong>{pais.cjg.mae != null ? pais.cjg.mae+' '+pais.cjg.smae : '---' }</strong></span>
</div>
</>}

</>}
</>}


{eu.cjg != undefined && <>
  {
  eu.cjg.tipo == "c" ? <>
  
  
  
  <div className='ncm'>
  <div>Casamento: <strong>{eu.cjg.lcasa != null ? eu.cjg.lcasa : '---'}</strong></div>
  <div>Data: <strong>{eu.cjg.dcasa != null ? this.formatarData(eu.cjg.dcasa) : '---'}</strong></div>
  {this.Bandeira(eu.bcasa)}
  </div>
  
  
  </> : <>
  <div>
    <span>
  Casamento: <strong>NÃO HOUVE CASAMENTO</strong>
    </span>
  </div>
  <div>
    <span>
    Estado Civil: <strong>{eu.status_c != null ? eu.status_c+ ' (a)': '---'}</strong>
    </span>
  </div>
  </>
  }
</>}




{
  eu.obs != null && <div>
  <span>Observações: <strong>{eu.obs}</strong></span>
  </div>
}


{
  eu.rtc != null && <div>
  <span>Retificação: <strong>{eu.rtc}</strong></span>
  </div>
}


</div>
{eu.cjg != undefined && <>
  <div class="area-r">

<div className='dados'>

<div style={{display:'flex',alignItems:'center',marginBottom:20}}>
  



{

eu.cjg.genero == "m" ?  <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img src={female} width={25} height={25} style={{marginRight:5}}/>

}
  <span>Denominação:<strong>  {eu.cjg.tipo == "c" ? "Cônjuge" : "COMPANHEIRO"} (a)</strong></span>
</div>


<div>
  <label>Nome: <strong>{eu.cjg.ncasada}</strong></label>
 </div>




<div className='ncm'>
  <div>nascimento: <strong>{eu.cjg.lnac != null ? eu.cjg.lnac : '---'}</strong></div>
  <div>Data: <strong>{eu.cjg.dnac != null ? this.formatarData(eu.cjg.dnac) : '---' }</strong></div>
  {this.Bandeira(eu.cjg.ncdd)}
  </div>




{eu.cjg.pai != null && <div>
<span>Nome do Pai: <strong>{eu.cjg.pai} {eu.cjg.spai}</strong></span>
</div>}

{eu.cjg.mae != null && <div>
<span>Nome da mãe: <strong>{eu.cjg.mae} {eu.cjg.smae}</strong></span>
</div>}



</div>
</div>

</>}


  </div>
  </>)
}




render() {

    var tree  = this.state.tree;






if(this.state.loader){
    return(<div style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'100vh'
    }}>
Carregando...
    </div>)
}


var tree  = this.state.tree;
console.log(tree)






    return (<>
      
      <div className="a4-page">
        <div style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'60px'
        }}>

      <span style={{fontSize:20}}>ÁRVORE GENEALÓGICA FAMILIA: {tree[0].sobrenome}</span>
      <hr/>

        </div>
    

<div style={{display:'table',margin:'auto',width:'95%',borderLeft:'solid 1px',borderRight:'solid 1px'}}>

<div className='a4-item' style={{borderTop:'solid 1px'}}>

<div class="area-l">


<div style={{display:'flex',alignItems:'center',marginBottom:20}}>

{
  this.state.tree[0].genero == "m"  ? <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img src={female} width={25} height={25} style={{marginRight:5}}/>
}
  


  <span>Denominação:<strong> dante causa</strong> {this.state.tree[0].nmr != null && <>
  - <strong>{this.state.tree[0].nmr}</strong>
  </>}</span>
 </div>

 <div>
  <label>Nome: <strong>{this.state.tree[0].label} {this.state.tree[0].sobrenome}</strong></label>
 </div>

<div className='ncm'>
  <div>
  <span>Nascimento: <strong>{this.state.tree[0].lnac != null ? this.state.tree[0].lnac : '---'}</strong></span>
  {this.Bandeira(this.state.tree[0].ncdd)}</div>
  
  <div>
  Data: <strong>{this.state.tree[0].dnac != null ? this.formatarData(this.state.tree[0].dnac ): '---' }</strong>
  </div>
</div>






{this.state.tree[0].pai != null && <div>
<span>Nome do Pai: <strong>{this.state.tree[0].pai} {this.state.tree[0].spai}</strong></span>
</div>}

{this.state.tree[0].mae != null && <div>
<span>Nome da mãe: <strong>{this.state.tree[0].mae} {this.state.tree[0].smae}</strong></span>
</div>}

{this.state.tree[0].cjg.tipo == "c" ? <>



<div className='ncm'>
<div>Casamento: <strong>{this.state.tree[0].cjg.lcasa != null ? this.state.tree[0].cjg.lcasa : '---'}</strong></div>
<div>Data: <strong>{this.state.tree[0].cjg.dcasa != null ? this.formatarData(this.state.tree[0].cjg.dcasa) : '---'}</strong></div>
{this.Bandeira(this.state.tree[0].bcasa)}
</div>


</> : <>
<div>
  <span>
Casamento: <strong>NÃO HOUVE CASAMENTO</strong>
  </span>
</div>

<div>
    <span>
    Estado Civil: <strong>{this.state.tree[0].status_c != null ? this.state.tree[0].status_c+ ' (a)' : '---'}</strong>
    </span>
  </div>
</>
}

{this.state.tree[0].flc && <>
  <div className='ncm'>
  <div>
  <span>Óbito: <strong>{this.state.tree[0].flc.local != null ? this.state.tree[0].flc.local : '---'}</strong></span>
  {this.Bandeira(this.state.tree[0].ncdd)}</div>
  
  <div>
  Data: <strong>{this.state.tree[0].flc.data != null ? this.formatarData(this.state.tree[0].flc.data ): '---' }</strong>
  </div>
</div>
</>}


{
  this.state.tree[0].obs != null && <div>
  <span>Observações: <strong>{this.state.tree[0].obs}</strong></span>
  </div>
}


{
  this.state.tree[0].rtc != null && <div>
  <span>Retificação: <strong>{this.state.tree[0].rtc}</strong></span>
  </div>
}



</div>


{this.state.tree[0].cjg && <>
<div class="area-r">

<div className='dados'>

<div style={{display:'flex',alignItems:'center',marginBottom:20}}>


{

this.state.tree[0].cjg.genero == "m" ?  <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img style={{marginRight:5}} src={female} width={25} height={25}/>

}


  <span>Denominação:<strong>  {this.state.tree[0].cjg.tipo == "c" ? "Cônjuge" : "COMPANHEIRO"} (a)</strong></span>
</div>


<div>
  <label>Nome: <strong>{this.state.tree[0].cjg.ncasada}</strong></label>
 </div>












<div className='ncm'>
<div>Nascimento: <strong>{this.state.tree[0].cjg.lnac != null ? this.state.tree[0].cjg.lnac : '---'}</strong></div>
<div>Data: <strong>{this.state.tree[0].cjg.dnac != null ? this.formatarData(this.state.tree[0].cjg.dnac) : '---' }</strong></div>
{this.Bandeira(this.state.tree[0].cjg.ncdd)}
</div>



{this.state.tree[0].cjg.pai != null && <div>
<span>Nome do Pai: <strong>{this.state.tree[0].cjg.pai} {this.state.tree[0].cjg.spai}</strong></span>
</div>}

{this.state.tree[0].cjg.mae != null && <div>
<span>Nome da mãe: <strong>{this.state.tree[0].cjg.mae} {this.state.tree[0].cjg.smae}</strong></span>
</div>}

{this.state.tree[0].cjg.tipo == "c" ? <>
</> : <>

</>}

</div>
</div>
</>
}





</div>



{this.RenderItem({
eu:this.FindByReq(tree,'Filho'),
pais:this.FindByReq(tree,''),
})}




{this.RenderItem({
eu:this.FindByReq(tree,'Neto'),
pais:this.FindByReq(tree,'Filho'),
avos:this.FindByReq(tree,'')
})}







       
</div>



      </div>

{this.FindByReq(tree,'Bisneto') && <>
<div style={{width:'100%',height:25,background:"#fff"}}>

</div>
  <div className="a4-page" style={{position:'relative'}}>
  <div style={{display:'table',margin:'auto',width:'95%',borderTop:'solid 1px ',borderLeft:'solid 1px',borderRight:'solid 1px'}}>
  {this.RenderItem({
eu:this.FindByReq(tree,'Bisneto'),
pais:this.FindByReq(tree,'Neto'),
avos:this.FindByReq(tree,'Filho'),
})}
</div>
  </div>
</>}
    
    <div className="a4-page avr-e" style={{position:'relative'}}>

<div className='prev-bnc'>

<this.Tree  nodes={[this.state.tree[0]]} parent={null} x={0} y={0} spacing={0} />
</div>
 



      </div>

   

    </>
   

      
  
    );
  }
}
// {tree[0].children.map((node) => this.renderTree(node))}
export default PreView;