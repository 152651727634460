import React from "react";

// Componente que representa um nó da árvore
const TreeNode = ({ node, x, y }) => {
  return (
    <g >
      <circle cx={x} cy={y} r={30} fill="lightblue" stroke="black" />
      <text x={x} y={y-15}   transform={`rotate(180, ${x}, ${y - 10})`} textAnchor="middle" dy=".3em">
        {node.label}
      </text>
    </g>
  );
};

// Função recursiva para renderizar os nós e as linhas de ligação
const Tree = ({ nodes, parent, x, y, spacing }) => {
  const totalWidth = (nodes.length - 1) * spacing; // Calcula a largura total do grupo de filhos
  const startX = x - totalWidth / 2; // Ajusta a posição inicial para centralizar o grupo de filhos

  return (
    <>
      {nodes.map((node, index) => {
        const newX = startX + index * spacing; // Ajuste horizontal para espaçar os nós
        const newY = y + 100;

        return (
          <g key={node.id} >
            {/* Linha que liga o nó atual ao nó pai */}
            {parent && (
              <line
                x1={x}
                y1={y+30}
                x2={newX}
                y2={newY}
                stroke="black"
                strokeWidth="2"
              />
            )}

            {/* Nó da árvore */}
            <TreeNode node={node} x={newX} y={newY} />

            {/* Renderizar filhos do nó atual */}
            {node.children && (
              <Tree nodes={node.children} parent={node} x={newX} y={newY} spacing={spacing / 2} />
            )}
          </g>
        );
      })}
    </>
  );
};

function Teste() {
  // Estrutura de exemplo da árvore
  const treeData = [
    {
        label:"filho",
        children:[
            {label:"Mãe",children:[{label:"Avó"},{label:"Avô"}]},
            {label:"Pai",children:[{label:"Avó"},{label:"Avô"}]},
        ]
    },
      
  ];

  return (
    <div className="App">
      <h1>Árvore SVG em React</h1>
      <svg width="100%" height="500">
      <g transform="rotate(180, 500, 250)">
        <Tree  nodes={treeData} parent={null} x={500} y={60} spacing={500} />
        </g>
      </svg>
    </div>
  );
}

export default Teste;
