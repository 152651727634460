import React, { Component } from 'react';
import logo from "../../../src/images/logo-c.png"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';
import { getCookie } from '../../comps/getCookie';
import { faPen, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticDialog } from 'react-st-modal';
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
    loader:true,
    data:[],
    item:undefined,
    nUser:undefined,
    edit:undefined
    };
  }



  componentDidMount(){
    document.title = "Lista de usuários"
 
    
    
      
    fetch(`${apiUrl}/listar-users/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie('auth_token')}`,
      }
    })
      .then(response => {
        if(response.status == 404){
          this.setState({error:true,loader:false})
          return;
        }
    
    
    
    if(response.status == 200){
      response.json().then(data=>{
        this.setState({data,loader:false})
        })
    }
      }).catch(err=>{
        toast.error("Falha de comunicação com o servidor!")
      })
    
    
    
    
      }

      maskString(str) {
        // Cria uma nova string composta por asteriscos
        return '*'.repeat(str.length);
      }


render() {

    var tree  = this.state.tree;


if(this.state.loader){
    return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
      <div className='loader'>
      <div class="spinner"></div>
      </div>
      </div>)
}

var data = this.state.data;

    return (
     
    <>
    
    
    
    <div className='py-12'>
<div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
<div className='col-12'>
        <div class="">
        <div class="card">

        <div class="card-header d-flex align-items-center justify-content-between">
        <strong>Usuários</strong>
        <button className='btn btn-primary' onClick={()=>{
this.setState({nUser:{user:'',pass:''}})
        }}>
          <FontAwesomeIcon icon={faPlus}/> Novo usuário
        </button>
        </div>


      <div class="card-body">
      <table class="table table-striped table-bordered">
    <thead class="thead-dark">
      <tr>

    <th>Usuário</th>
    <th>Senha</th>
    
      
      <th>Opções</th>

      </tr>
    </thead>
    <tbody>
      
      {data.map((item,index)=><>
        <tr>

        <td style={{textAlign:'center'}}>{item.user}</td>
        <td>{this.maskString(item.pass)}</td>
        <td style={{display:'flex',justifyContent:'center'}}>

        <div className='btn-group btn-group-sm'>
  <button className='btn btn-info' onClick={()=>{
               this.setState({edit:item})
                }}>
    <FontAwesomeIcon color='#fff' icon={faPen}/>
  </button>

  <button className='btn btn-danger' onClick={()=>{
  this.setState({trash:item})             
  }}>
  <FontAwesomeIcon icon={faTrash}/>
</button>

  </div>



        </td>
   
      </tr>
      </>)}
      
    </tbody>
      </table>
      </div>

      </div>
      </div>
      </div>
      </div>
      </div>




      <StaticDialog
        isOpen={this.state.trash != undefined}
        title="Tem certeza de que deseja continuar?"
        onAfterClose={(result) => {
 this.setState({trash:undefined})
          // do something with dialog result
        }}
    >
      <div style={{margin:10}}>
<span>
Se você confirmar a exclusão, não será possível recuperar os dados posteriormente.
</span>
      </div>
      <div className='modal-btn'>
<button className='btn btn-danger' onClick={(e)=>{
var trash = this.state.trash;


e.target.classList.add('load')

fetch(`${apiUrl}/deletar-user/`, {
 method: 'POST',
 headers: {
   'Content-Type': 'application/json',
'Authorization': `Bearer ${getCookie('auth_token')}`,
 },
 body: JSON.stringify({
 token:trash.token
 }),
})
 .then(response => {
  
   
   e.target.classList.remove('load')



   if(response.status == 402){
  toast.error("Operação proibida!")
   }




if(response.status == 200){
 this.setState((prevState) => ({
   data: prevState.data.filter(item => item.token !== trash.token),
   trash:undefined
 }));
toast.success('Árvore deletada!')
}

}).catch(err=>{
 e.target.classList.remove('load')
toast.error("Falha de comunicação com servidor!")
})
}}>
<FontAwesomeIcon icon={faTrash}/> Excluir
</button>

</div>
</StaticDialog>


<StaticDialog
        isOpen={this.state.nUser != undefined}
        title="Adicionar usuário"
        onAfterClose={(result) => {
 this.setState({nUser:undefined})
          // do something with dialog result
        }}
    >
      <div style={{margin:10}}>
      

      <div class="form-group mt-2">
      <label>Usuário:</label>
      <input type="text" class="form-control form-control-sm" onChange={(e)=>{

this.setState((prevState) => ({
  nUser: {
    ...prevState.nUser,
    ['user']: e.target.value
  }
}));



      }}/>
      </div>

      <div class="form-group mt-2">
      <label>Senha:</label>
      <input type="text" class="form-control form-control-sm" onChange={(e)=>{
        this.setState((prevState) => ({
          nUser: {
            ...prevState.nUser,
            ['pass']: e.target.value
          }
        }));
      }}/>
      </div>



      </div>
      <div className='modal-btn'>
<button className='btn btn-primary' onClick={(e)=>{
var user = this.state.nUser;

if(user.user.trim().length == 0){
  toast.error("Informe o usuário!")
  return
}
if(user.pass.trim().length == 0){
  toast.error("Informe a senha!")
  return
}


e.target.classList.add('load')

 fetch(`${apiUrl}/criar-user/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
 'Authorization': `Bearer ${getCookie('auth_token')}`,
  },
  body: JSON.stringify(user),
})
  .then(response => {
   
    
    e.target.classList.remove('load')

if(response.status == 200){
response.json().then(data=>{
  this.setState((prevState) => ({
    data: [...prevState.data, {user:user.user,pass:user.pass,token:data.token}],
    nUser:undefined
  }))
  toast.success("Usuário adicionado!")
})
}

}).catch(err=>{
  e.target.classList.remove('load')
toast.error("Falha de comunicação com servidor!")
})




}}>
  <FontAwesomeIcon icon={faSave}/> Salvar
</button>
      </div>
    </StaticDialog>

    <StaticDialog
        isOpen={this.state.edit != undefined}
        title="Editar usuário"
        onAfterClose={(result) => {
 this.setState({edit:undefined})
          // do something with dialog result
        }}
    >
      <div style={{margin:10}}>
    
      <div class="form-group mt-2">
      <label>Usuário:</label>
      <input type="text" class="form-control form-control-sm" value={this.state.edit != undefined && this.state.edit.user} onChange={(e)=>{

this.setState((prevState) => ({
  edit: {
    ...prevState.edit,
    ['user']: e.target.value
  }
}));



      }}/>
      </div>

      <div class="form-group mt-2">
      <label>Senha:</label>
      <input type="text" class="form-control form-control-sm" value={this.state.edit != undefined && this.state.edit.pass} onChange={(e)=>{
        this.setState((prevState) => ({
          edit: {
            ...prevState.edit,
            ['pass']: e.target.value
          }
        }));
      }}/>
      </div>
        </div>
              <div className='modal-btn'>
<button className='btn btn-primary' onClick={(e)=>{
var data = this.state.edit;
e.target.classList.add('load')

fetch(`${apiUrl}/atualizar-user/`, {
 method: 'POST',
 headers: {
   'Content-Type': 'application/json',
'Authorization': `Bearer ${getCookie('auth_token')}`,
 },
 body: JSON.stringify(data),
})
 .then(response => {
  
   
   e.target.classList.remove('load')



   if(response.status == 402){
  toast.error("Operação proibida!")
   }




if(response.status == 200){




  const updatedData = this.state.data.map(item => {
    if (item.token === data.token) {
      return { ...item, user:data.user,pass:data.pass }; // Atualizando o nome do item
    }
    return item; // Retornando o item sem alterações
  });
  this.setState({ data: updatedData,edit:undefined });



toast.success('Usuário atualizado!')
}

}).catch(err=>{
 e.target.classList.remove('load')
toast.error("Falha de comunicação com servidor!")
})
}}>
  <FontAwesomeIcon icon={faSave}/> Salvar
</button>
</div>
    </StaticDialog>
    
    </>
  
    );
  }
}

export default Users;