import { useEffect, useState } from 'react';
import { Route, Routes, Router, useNavigate, useLocation} from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/App.css"
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Login from './pages/Login';
import { toast, ToastContainer } from 'react-toastify';
import Header from './comps/Header';
import NovaArvore from './pages/NovaArvore';
import Tree from './pages/tree';
import Arvore from './pages/Arvore';
import TreePrev from './pages/TreePrev';
import Teste from './pages/Teste';
import EdtArvore from './pages/EdtArvore';
import PreView from './pages/Preview';
import Users from './pages/Users';
import Assessores from './pages/Assessores';
import PreView2 from './pages/Preview2';


function App() {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigateTo = useNavigate();
  const [isLoad, setIsload] = useState(true);
  useEffect(() => {
    const authToken = document.cookie.includes('auth_token');
    setIsload(false)
    setIsAuthenticated(authToken);
  }, []);


  if(isLoad){
    return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
    <div className='loader'>
    <div class="spinner"></div>
    </div>
    </div>)
  }


if(isAuthenticated){
  
  return (
<>
<ToastContainer/>
<Header/>
<Routes>
<Route  path="/"  element={<Home  navigateTo={navigateTo}/>} />
<Route  path="/nova-arvore"  element={<NovaArvore navigateTo={navigateTo}/>} />
<Route  path="/arvore/:token"  element={<TreePrev navigateTo={navigateTo}/>} />
<Route  path="/arvore/:token/edit"  element={<EdtArvore navigateTo={navigateTo}/>} />
<Route  path="/teste"  element={<Teste navigateTo={navigateTo}/>} />
<Route  path="/users"  element={<Users navigateTo={navigateTo}/>} />
<Route  path="/assessores"  element={<Assessores navigateTo={navigateTo}/>} />

</Routes>
</>
  );
}else{
  return(<>
  
<Routes>

<Route  path="/"  element={<Login/>} />
<Route  path="/preview-old/:token"  element={<PreView/>} />
<Route  path="/preview/:token"  element={<PreView2/>} />
</Routes>
<ToastContainer/>
  </>)
}
}

export default App;