import { faAngleLeft, faClose, faEye, faMagnifyingGlass, faPen, faPlug, faPlus, faShare, faShareAlt, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';
import { StaticDialog } from 'react-st-modal';
import { toast } from 'react-toastify';
import { getCookie } from '../../comps/getCookie';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    loader:true,
    busca:'',
    search:undefined,
    trash:undefined
    };
  }

  componentDidMount(){
document.title = 'Home'
fetch(`${apiUrl}/results`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${getCookie('auth_token')}`,
  }
})
  .then(response => {
    response.json().then(data=>{
      this.setState({data,loader:false})
    })
  })
  }

  render() {
    if(this.state.loader){
      return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <div className='loader'>
        <div class="spinner"></div>
        </div>
        </div>)
    }
    var {data} = this.state;
    return (<>
   
<div className='py-12'>
<div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
<div className='col-12'>
        <div class="">
        <div class="card">

        <div class="card-header d-flex align-items-center justify-content-between">
        <strong>Árvore</strong>
        <button className='btn btn-primary' onClick={()=>{
          this.props.navigateTo('/nova-arvore')
        }}>
          <FontAwesomeIcon icon={faPlus}/> Nova árvore
        </button>
        </div>


      <div class="card-body">
    
        <div class="mb-3">
          <div class="input-group" style={{
            
          }}>
           
            <input type="text" class="form-control" id="searchInput" onChange={(e)=>{
             if(e.target.value.length == 0){
              this.setState({search:undefined})
              return;
             }
             this.setState({busca:e.target.value})
            }} placeholder="Digite para buscar..."/>
            <button class="btn-psq3">
              <FontAwesomeIcon icon={faClose}/>
            </button>
            <button class="btn btn-primary" onClick={(e)=>{
              var value = this.state.busca;
         
              if(value.trim().length == 0){
                return;
              }

              e.target.classList.add('load')

              fetch(`${apiUrl}/buscar/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  // outras headers, se necessário
                },
                body: JSON.stringify({
                value
                }), // corpo da requisição em formato JSON
              })
                .then(response => {
                 
                  e.target.classList.remove('load')  
                 

if(response.status == 200){
response.json().then(search=>{
  this.setState({search})
})
}


                })


            }}>
           <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </button>
          </div>
        </div>
        <div id="resultList">
      
      {this.state.search == undefined ? <>






        {data.length > 0 ? <>
          
        <table class="table table-striped table-bordered arvores-l">
    <thead class="thead-dark">
      <tr>

        <th>Árvore genealógica</th>
      
        {localStorage.getItem("master") == "true" && <>
          <th>Usuário</th>
        </> }

        {localStorage.getItem("master") == "true" && <>
          <th>assessor</th>
        </> }
      
      <th>Opções</th>

      </tr>
    </thead>
    <tbody>
    {data.map((item)=><>
    <tr>
 <td style={{textAlign:'center'}}> {item.sobrenome != null ? item.sobrenome : 'Sobrenome a definir'}</td>
 {localStorage.getItem("master") == "true" &&  <td style={{textAlign:'center'}}>{item.user}</td>  }
 {localStorage.getItem("master") == "true" &&  <td style={{textAlign:'center'}}>{item.assr}</td>  }

<td style={{display:'flex',justifyContent:'center'}}>
  <div className='btn-group btn-group-sm'>
  <button className='btn btn-success' onClick={()=>{
                  this.props.navigateTo("/arvore/"+item.arvore_id)
                }}>
    <FontAwesomeIcon icon={faEye}/>
  </button>

  <button className='btn btn-info' onClick={()=>{
                  this.props.navigateTo(`/arvore/${item.arvore_id}/edit`)
                }}>
    <FontAwesomeIcon color='#fff' icon={faPen}/>
  </button>

  <button className='btn btn-danger' onClick={()=>{
                 this.setState({trash:item})
                }}>
    <FontAwesomeIcon icon={faTrash}/>
  </button>
  </div>

</td> 
    
    
    </tr>
    </>)}
    </tbody>
    </table>
          </> : <>
          Sem resultados
          </>}
      </> : <>
      
     {this.state.search.length > 0 ? <>
     
      <div class="list-group">
              {this.state.search.map((item)=><>
                <div class="list-group-item" onClick={()=>{
                  this.props.navigateTo("/arvore/"+item.id)
                }}>  
                <span>{item.sobrenome != '' ? item.sobrenome : 'Sobrenome a definir'}</span>
                 </div>
              </>)}
            </div>
     
     </> : <>Não foram encontrados resultados!</>}
         
      </>}
          
          
        
        </div>
      </div>
    </div>
        </div>
  
</div>
      
   

    
   
</div>
</div>

<StaticDialog
        isOpen={this.state.trash != undefined}
        title="Tem certeza de que deseja continuar?"
        onAfterClose={(result) => {
 this.setState({trash:undefined})
          // do something with dialog result
        }}
    >
      <div style={{margin:10}}>
<span>
Se você confirmar a exclusão, não será possível recuperar os dados posteriormente.
</span>
      </div>
      <div className='modal-btn'>
<button className='btn btn-danger' onClick={(e)=>{

var trash = this.state.trash;


 e.target.classList.add('load')

 fetch(`${apiUrl}/deletar-arvore/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
 'Authorization': `Bearer ${getCookie('auth_token')}`,
  },
  body: JSON.stringify({
  id:trash.arvore_id
  }),
})
  .then(response => {
   
    
    e.target.classList.remove('load')

if(response.status == 200){
  this.setState((prevState) => ({
    data: prevState.data.filter(item => item.arvore_id !== trash.arvore_id),
    trash:undefined
  }));
toast.success('Árvore deletada!')
}

}).catch(err=>{
  e.target.classList.remove('load')
toast.error("Falha de comunicação com servidor!")
})




}}>
  <FontAwesomeIcon icon={faTrash}/> Excluir
</button>
      </div>
    </StaticDialog>


        </>
    );
  }
}

export default Home;