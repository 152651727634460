import React, { Component } from 'react';
import { apiUrl } from '../../comps/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import doc from "../../images/doc.png"
import { toast } from 'react-toastify';
class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
      loader:true,
      falec:false,
      tree:[]
    };
  }

componentDidMount(){
    document.title = "Árvore"
    fetch(`${apiUrl}/arvore/`+window.location.pathname.split("/")[2], {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // outras headers, se necessário
        },
      })
        .then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({
        data,
        loader:false,
        tree:{name:`${data.nome} ${data.sobrenome}`}
    })
})
}else{

}
        })
}

handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [id]: value
      }
    }));
  }


  handleFileChange = (e) => {
    const file = e.target.files[0];
    
    // Verifica se o arquivo é um PDF
    if (file && file.type !== 'application/pdf') {
      toast.warn("Apenas arquivos PDF são permitidos!")
      e.target.value = ''
      return;
    }

    // Se for um PDF válido, atualiza o estado e limpa o erro
    this.setState({
      [e.target.name]: file,
      error: '', // Limpa qualquer mensagem de erro anterior
    });
  };

CustomTreeNode({ nodeDatum }) {
    const { name, gender } = nodeDatum;
  
    return(<></>)

  }
  render() {
    if(this.state.loader){
        return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
          <div className='loader'>
          <div class="spinner"></div>
          </div>
          </div>)
      }
      if(this.state.data.length ==0){
        return(<>
        <div class="p404 doc">
   <img src={doc}/>
   <p>Desculpe, Esse documento não foi encontrado.</p>
   <button  class="btn btn-primary" onClick={()=>{
    this.props.navigateTo(-1)
   }}>
     <FontAwesomeIcon icon={faArrowLeft} />
      Voltar
   </button>
</div>

        </>)
      }
      var d = this.state.data;
      return (<>
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
              this.props.navigateTo(-1)
          }}>
   <FontAwesomeIcon icon={faAngleLeft}/> Árvore</button>
          </div>
          </header>
      
          <div class="container-fluid full-height">
    <div class="row h-100">
      <div class="col-12 col-md-6 bg-primary text-white d-flex justify-content-center align-items-center">
     
     
      <div class="container mt-5">
    <div class="card">
      <div class="card-header">
        Dados
      </div>
      <div class="card-body add-usr">

      <div class="mb-3">
            <label for="nome" class="form-label">Nome</label>
            <input placeholder='Digite:' value={d.nome} type="text" class="form-control" id="nome"  onChange={this.handleInputChange} autocomplete="off" />
          </div>


          <div class="mb-3">
            <label for="sobrenome" class="form-label">Sobrenome</label>
            <input placeholder='Digite:' value={d.sobrenome} type="text" class="form-control" id="sobrenome" onChange={this.handleInputChange} autocomplete="off"/>
          </div>




          <div class="mb-3">
            <label for="nascimento" class="form-label">Nascimento</label>
            <input placeholder='Digite:' value={d.data_nac} type="date" class="form-control" id="nascimento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="nascimento-file" class="form-label">Anexar Certidão de Nascimento</label>
            <input placeholder='Digite:' type="file" class="form-control" name="file1" onChange={this.handleFileChange} id="nascimento-file"/>
          </div>

          <div class="mb-3">
            <label for="casamento" class="form-label">Casamento</label>
            <input placeholder='Digite:' value={d.casamento} type="date" class="form-control" id="casamento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="casamento-file" class="form-label">Anexar Certidão de Casamento</label>
            <input placeholder='Digite:' type="file" class="form-control" name="file2" onChange={this.handleFileChange} id="casamento-file"/>
          </div>
      
        {true && <>
          <div class="mb-3">
            <label for="falecimento" class="form-label">Falecimento</label>
            <input placeholder='Digite:' value={d.falec} type="date" class="form-control" id="falecimento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="local_falecimento" class="form-label">Local de Falecimento</label>
            <input placeholder='Digite:' value={d.local_falec} type="text" class="form-control" id="local_falecimento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="falecimento-file" class="form-label">Anexar Certidão de Falecimento</label>
            <input type="file" class="form-control" name="file3" onChange={this.handleFileChange} id="falecimento-file"/>
          </div>
        </>}
         

          <div class="mb-3">
            <label for="observacoes" class="form-label">Observações</label>
            <textarea placeholder='Digite:' value={d.obs}  type="text" class="form-control" id="observacoes" onChange={this.handleInputChange} autocomplete="off"/>
          </div>


        <div class="mb-3 d-flex justify-content-center">
            


            <button className='btn btn-primary' >
<FontAwesomeIcon icon={faSave}/> Salvar
            </button>


          

        </div>



      </div>
    </div>
  </div>

   
      </div>
      <div class="col-12 col-md-6 bg-secondary text-white d-flex justify-content-center align-items-center">
     
      <Tree
        data={{name:'teste'}}
        orientation="vertical"
        translate={{ x: 450, y: 100 }}
        pathFunc="step"
        nodeSvgShape={{
          shape: 'rect',
          shapeProps: {
            width: 120,
            height: 60,
            x: -60,
            y: -30,
            strokeWidth: 2,
            stroke: 'black',
            fill: '#f0f0f0',
          },
        }}
        styles={{
          links: {
            link: {
              stroke: '#000000',
              strokeWidth: 2,
            },
          },
        }}
        separation={{ siblings: 1, nonSiblings: 2 }}
        renderCustomNodeElement={(rd3tProps) => (
          <this.CustomTreeNode {...rd3tProps} />
        )}
      />
     

      </div>
    </div>
  </div>
    
  
          </>
      );
  }
}

export default Tree;
