import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';
import { toast } from 'react-toastify';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:'',
      senha:'',
      errors: {
        email: '',
        password: ''
      }
    };
  }

  componentDidMount(){

  }

  setCookie = (name, value, expirationDays) => {
    const expirationDate = new Date();
    if(undefined){
  
    }
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
  
    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
  };

_Login(){
  var body = this.state;
              
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(body.email == ""){
      toast.error('Preencha o campo de usuário', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
  
          });
          return false;
  }




  if(body.senha == ""){
      toast.error('Preencha o campo de senha', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
  
          });
          return false;
  }

  fetch(`${apiUrl}/auth/login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({
      email:this.state.email,
      senha:this.state.senha
    }), // corpo da requisição em formato JSON
  })
    .then(response => {
     
      
     

if(response.status == 200){

response.json().then(data=>{
console.log(data)
this.setCookie('auth_token',data.access_token,30);
if(data.master == true){
localStorage.setItem('master','true')
}else{
localStorage.setItem('master','normal')
}

toast.success("Sucesso!")
window.location = ""
})
}
if(response.status == 404){
response.json().then(data=>{
toast.error("Erro, Verifique o email e a senha")
})
}


    })
    
    .catch(error => {
     toast.error("Erro:")
    });
}

handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); // Evita o comportamento padrão do Enter
    this._Login()
  }
};



  render() {
    const { errors } = this.state;
    return (
      <>
      
      <div id="app" data-v-app="">
     
<div style={{display:'flex',justifyContent:'center'}}>
<strong style={{position:'absolute',top:100,fontSize:20}}>
  SISTEMA DE ÁRVORE GENEALOGICA
</strong>
</div>
     
     <div className="area-login">
      
    
       <div class="logo">
         <Link to="/" className='logos'>
         
        
         </Link>
       </div>




       <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg area-dg">
      
           <div>
             <label className="block font-medium text-sm text-gray-700" htmlFor="email">
               <span>Usuário:</span>
             </label>
             <input
               className={`border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full ${errors.email && 'is-invalid'}`}
               id="email"
               type="email"
               name="email"
               placeholder='Digite:'
               onChange={(e)=>{
                 this.setState({email:e.target.value})
               }}
               onKeyDown={this.handleKeyDown}
               value={this.state.email}
               autoFocus
       
             />
             {errors.email && <div className="invalid-feedback">{errors.email}</div>}
           </div>
           <div className="mt-4">
             <label className="block font-medium text-sm text-gray-700" htmlFor="password">
               <span>Senha:</span>
             </label>
             <input
               className={`border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full ${errors.password && 'is-invalid'}`}
             placeholder='Digite:'
               type="password"
               name="password"
               onChange={(e)=>{
                 this.setState({senha:e.target.value})
               }}
               onKeyDown={this.handleKeyDown}
               value={this.state.senha}
             
             />
             {errors.password && <div className="invalid-feedback">{errors.password}</div>}
           </div>
           <div className="block mt-4">
           
           
           </div>
           <div className="flex items-center justify-end mt-4">
           
             <button
             
               className="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-4"
               onClick={()=>{
             this._Login()
               }
             
             }
             >
              ENTRAR
             </button>
           </div>

       </div>
  
     </div>
    

   </div>

      
      </>
    );
  }
}

export default Login;